<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>组织部门：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-cascader v-model="searchForm.org_id" :options="org_id_list" :props="props"
                                 clearable></el-cascader>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>缴费项目：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.project_id" clearable>
                        <el-option v-for="item in project_id_list" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>支付方式：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.pay_type" clearable>
                        <el-option v-for="item in pay_type_list" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>支付状态：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.pay_status" clearable>
                        <el-option v-for="item in pay_status_list" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>学工号：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.card_number" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>姓名：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.user_name" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>交易时间：</span>
                </div>
                <div class="baseSearchOneRight2">
                    <el-date-picker v-model="searchForm.pay_time" type="daterange" unlink-panels
                                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                                    value-format="YYYY-MM-DD"></el-date-picker>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openExport" v-if="authorityStatus.projectExport.show">导出全部</el-button>
            </div>
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                <el-table-column prop="order_no" label="业务订单号"></el-table-column>
                <el-table-column prop="card_number" label="学工号"></el-table-column>
                <el-table-column prop="user_name" label="姓名"></el-table-column>
                <el-table-column prop="class_name" label="组织部门"></el-table-column>
                <el-table-column prop="project_name" label="缴费项目"></el-table-column>
                <el-table-column prop="pay_price" label="缴费金额"></el-table-column>
                <el-table-column prop="pay_type_name" label="支付方式"></el-table-column>
                <el-table-column prop="transaction_id" label="支付订单号"></el-table-column>
                <el-table-column prop="pay_status_name" label="支付状态"></el-table-column>
                <el-table-column prop="pay_time" label="交易时间" width="110"></el-table-column>
                <!--<el-table-column prop="name" label="订单备注"></el-table-column>-->
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="danger" @click.prevent="openRefund(scope.row)"
                                 v-if="scope.row.refund_price < (scope.row.pay_price * 1) && authorityStatus.projectRefund.show">
                            退款
                        </el-link>
                        <el-link class="tableButton" type="primary"
                                 @click.prevent="openReceipt(scope.row)" v-if="authorityStatus.projectDown.show">
                            下载收据
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <el-dialog title="退款" v-model="dialogVisible" width="600px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px">
                <el-form-item label="学工号">
                    {{selMsg.card_number}}
                </el-form-item>
                <el-form-item label="姓名">
                    {{selMsg.user_name}}
                </el-form-item>
                <el-form-item label="项目名称">
                    {{selMsg.project_name}}
                </el-form-item>
                <el-form-item label="支付方式">
                    {{selMsg.pay_type_name}}
                </el-form-item>
                <el-form-item label="支付订单号">
                    {{selMsg.order_no}}
                </el-form-item>
                <el-form-item label="缴费金额">
                    {{selMsg.pay_price}}
                </el-form-item>
                <el-form-item label="退款类型">
                    <el-radio-group v-model="ruleForm.refund_type">
                        <el-radio :label="1">微信</el-radio>
                        <el-radio :label="2">现金</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="退款金额">
                    <el-input-number v-model="ruleForm.refund_price" controls-position="right"
                                     :min="0" :max="selMsg.pay_price*1"></el-input-number>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="ruleForm.remarks" :autosize="{ minRows: 2 }"
                              show-word-limit maxlength="100"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveRefund">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="下载收据" v-model="receiptVisible" width="1600px">
            <div class="receipt" ref="imageTofile">
                <div class="receiptTitle">
                    <span>{{showMsg.school_name}}缴费电子收据</span>
                </div>
                <div class="receiptSubtitle">
                    <div class="receiptSubtitleLeft">
                        <span>订单号：{{selMsg.order_no}}</span>
                    </div>
                    <div class="receiptSubtitleRight">
                        <span>交易时间：{{selMsg.pay_time}}</span>
                    </div>
                </div>
                <div class="receiptMain receiptBT receiptBL clearfloat">
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop">
                                    <span>姓名</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom">
                                    <span>{{selMsg.user_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学工号</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.card_number}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>支付方式</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.pay_type_name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>组织机构</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.class_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学年学期</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>无</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne2 receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop" style="padding-top: 30px;">
                                    <span>缴费项目</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom" style="padding-top: 10px;">
                                    <span>{{selMsg.project_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>应收总金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.y_price}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>实收金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.pay_price}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="receiptMainLeftOne3 receiptBL receiptBR receiptBB">
                    <span>人民币（大写）：{{selMsg.daxie}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>收款单位（盖章）：{{showMsg.school_name}}</span>
                    <span style="float: right;">收款人：{{showMsg.school_name}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>注：本电子收据仅作为线上缴费确认依据，不作为报销凭证</span>
                </div>
                <!--crossorigin="anonymous"-->
                <img class="receiptMainZhang" :src="showMsg.receipt_seal">
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="downImage">下 载</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import XLSX from 'xlsx'

    import html2canvas from 'html2canvas'

    import {businessGet, businessPost} from '../../api/business.js'

    import Global from '../../Global.js'

    import {utilsGetOptionsList, utilsGetAuthority, utilsExportExcel,utilsDX} from '../../utils/utils.js'

    export default {
        name: "payPaid",
        components: {
            publicPage,
            publicTable
        },
        data() {
            return {
                org_id_list: [],
                props: {
                    value: 'id',
                    label: 'name',
                    children: 'son',
                    checkStrictly: true
                },
                project_id_list: [],
                pay_type_list: [],
                pay_status_list: [],
                searchForm: {
                    org_id: '',
                    project_id: '',
                    pay_type: '',
                    pay_status: '',
                    card_number: '',
                    user_name: '',
                    pay_time: [],
                },
                searchHeight: 0,
                tableLoading: false,
                tableData: [],
                total: 0,
                dialogVisible: false,
                ruleForm: {
                    refund_type: 1,
                    refund_price: 0,
                    remarks: '',
                },
                rules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                selMsg: null,
                receiptVisible: false,
                showMsg: {
                    school_name: '',
                    receipt_seal: '',
                },
                authorityStatus: {
                    projectRefund: {
                        method: 'post',
                        url: '/admin/v1/refund',
                        show: false,
                    },
                    projectDown: {
                        method: 'post',
                        url: 'dianzishouju',
                        show: false,
                    },
                    projectExport: {
                        method: 'get',
                        url: '/admin/v1/order_export',
                        show: false,
                    },
                },
            }
        },
        mounted() {
            utilsGetAuthority(this.authorityStatus);
            this.userInfo = JSON.parse(localStorage.getItem(Global.GlobalUserInfoLocalName));
            this.showMsg.school_name = this.userInfo.school_name;
            this.getMsg();
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseSearch');
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                this.searchHeight = searchHeight;
                this.getTableData();
                let dom = document.querySelector('.el-cascader');
                dom.style.width = '100%';
            })
            this.get_project_id_list();
            this.pay_type_list = utilsGetOptionsList('Global_pay_type_obj')
            this.pay_status_list = utilsGetOptionsList('Global_pay_status_obj')
            this.getTreeData();
        },
        methods: {
            getTreeData() {
                let url = '/admin/v1/organization?type=1';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.org_id_list = res.data.data;
                    }
                })
            },
            get_project_id_list() {
                let url = '/admin/v1/projects?page=1&page_pre=500';
                businessGet(url).then(res => {

                    if (res.data.status == 200) {
                        let newArray = [];
                        res.data.data.list.forEach(item => {
                            let a = {
                                value: item.id,
                                label: item.name
                            }
                            newArray.push(a);
                        })
                        this.project_id_list = newArray;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            openExport() {
                let url = '/admin/v1/order?is_export=1';
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'org_id') {
                            url = url + '&' + k + '=' + this.searchForm[k][this.searchForm[k].length - 1];
                        }
                        else if (k == 'pay_time') {
                            if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                utilsExportExcel(url)
            },
            base64ToBlob(code) {
                let parts = code.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], {type: contentType});
            },
            downloadFile(fileName, content) {
                let aLink = document.createElement('a');
                let blob = this.base64ToBlob(content); //new Blob([content]);
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
                aLink.download = fileName;
                aLink.href = URL.createObjectURL(blob);
                aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
            },
            downImage() {
                // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
                const opts = {
                    useCORS: true,
                    backgroundColor: null
                }
                html2canvas(this.$refs.imageTofile, opts).then((canvas) => {
                    let url = canvas.toDataURL('image/png');
                    this.downloadFile('电子收据.png', url);
                })
            },
            openReceipt(val) {
                this.selMsg = val;
                this.selMsg.daxie = utilsDX(this.selMsg.pay_price)
                this.receiptVisible = true;
            },
            exportExcel() {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    //数据表格
                    let exportList = [];
                    var aoa = [
                        ['支付宝账号', '支付宝账号姓名', '转账金额', '转账人', '转账时间', '转账状态', '备注'],
                    ];
                    let dayinName = ['receiverId', 'receiverName', 'transAmount', 'operaterName', 'fuTransDate', 'statusName', 'remark']
                    exportList.forEach(item => {
                        let a = []
                        dayinName.forEach(item2 => {
                            a.push(item[item2])
                        })
                        aoa.push(a)
                    })
                    //创建book
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(aoa);
                    //设置列宽
                    ws['!cols'] = [
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                    ];
                    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
                    //输出
                    let exportName = "转账记录.xlsx"
                    XLSX.writeFile(wb, exportName);
                }).catch(() => {
                })
            },
            saveRefund() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let url = '/admin/v1/refund';
                        let data = {
                            order_id: this.selMsg.id,
                            card_number: this.selMsg.card_number,
                            user_name: this.selMsg.user_name,
                            project_name: this.selMsg.project_name,
                            project_id: this.selMsg.project_id,
                            class_name: this.selMsg.class_name,
                            pay_price: this.selMsg.pay_price,
                            refund_price: this.ruleForm.refund_price,
                            refund_type: this.ruleForm.refund_type,
                            remarks: this.ruleForm.remarks,
                        }
                        businessPost(url, data).then(res => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.dialogVisible = false;
                                this.getTableData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                })
            },
            openRefund(val) {
                this.selMsg = val;
                this.ruleForm.refund_type = 1;
                this.ruleForm.refund_price = 0;
                this.ruleForm.remarks = '';
                this.dialogVisible = true;
            },
            cleanAll() {
                this.searchForm.org_id = '';
                this.searchForm.project_id = '';
                this.searchForm.pay_type = '';
                this.searchForm.pay_status = '';
                this.searchForm.card_number = '';
                this.searchForm.user_name = '';
                this.searchForm.pay_time = [];
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/order?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'org_id') {
                            url = url + '&' + k + '=' + this.searchForm[k][this.searchForm[k].length - 1];
                        }
                        else if (k == 'pay_time') {
                            if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        let tableData = res.data.data.list;
                        tableData.forEach(item => {
                            item.pay_type_name = Global.Global_pay_type_obj[item.pay_type];
                            item.pay_status_name = Global.Global_pay_status_obj[item.pay_status];
                        })
                        this.tableData = tableData;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getMsg() {
                let url = '/admin/v1/base_config/' + this.userInfo.school_code;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        if (res.data.data.receipt_seal) {
                            this.showMsg.receipt_seal = res.data.data.receipt_seal;
                        }
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .receiptBT {
        border-top: 1px solid #303133;
    }

    .receiptBL {
        border-left: 1px solid #303133;
    }

    .receiptBR {
        border-right: 1px solid #303133;
    }

    .receiptBB {
        border-Bottom: 1px solid #303133;
    }

    .receipt {
        width: 1440px;
        height: 712px;
        box-sizing: border-box;
        padding: 20px 10px;
        border: 1px solid #eee;
        margin: 0 auto;
        position: relative;
        background-color: #fff;
    }

    .receiptTitle {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: #303133;
    }

    .receiptSubtitle {
        width: 100%;
        height: 34px;
        line-height: 34px;
        font-size: 12px;
        color: #303133;
    }

    .receiptSubtitleLeft {
        height: 100%;
        float: left;
    }

    .receiptSubtitleRight {
        height: 100%;
        float: right;
    }

    .receiptMain {
        width: 100%;
        box-sizing: border-box;
    }

    .receiptMainLeft {
        width: 50%;
        box-sizing: border-box;
        float: left;
    }

    .receiptMainLeftOne {
        width: 100%;
        height: 58px;
        box-sizing: border-box;
    }

    .receiptMainLeftOneIn {
        width: 50%;
        height: 100%;
        box-sizing: border-box;
        float: left;
    }

    .receiptMainLeftMsg {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .receiptMainLeftMsgTop {
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        padding: 8px 10px 0 10px;
        color: #606266;
        font-size: 12px;
        text-align: left;
    }

    .receiptMainLeftMsgBottom {
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        padding: 0 10px;
        color: #000000;
        font-size: 12px;
        text-align: left;
        font-weight: bold;
    }

    .receiptMainLeftOne2 {
        width: 100%;
        height: 116px;
        box-sizing: border-box;
    }

    .receiptMainLeftOne3 {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        line-height: 40px;
        text-align: left;
        color: #606266;
        padding: 0 16px;
        font-size: 14px;
    }

    .receiptMainZhang {
        width: 200px;
        height: 200px;
        box-sizing: border-box;
        object-fit: contain;
        position: absolute;
        top: 234px;
        left: 68px;
    }

</style>

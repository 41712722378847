<template>
    <div class="Home">
        <div class="HomeTop">
            <div class="HomeTopLeft">
                <el-image class="HomeTopLeftImg" style="width: 40px; height: 40px;border-radius: 50%;"
                          :src="schoolLogoUrl" fit="cover"></el-image>
                <div class="HomeTopLeftText">
                    <span>{{schoolName}}</span>
                </div>
            </div>
            <div class="HomeTopRight">
                <div class="HomeTopRightText">
                    <el-button type="text" style="margin-top: 2px;margin-right: 36px;" @click="jumpPage">下载中心
                    </el-button>
                </div>
                <el-image class="HomeTopRightImg" :src="userInfo.logo" fit="cover" v-if="userInfo"></el-image>
                <div class="HomeTopRightText">
                    <el-dropdown @command="handleCommand">
                        <div style="line-height: 40px;cursor: pointer;">
                            <span v-if="userInfo">{{userInfo.name}}&nbsp;</span>
                            <el-icon :size="12">
                                <arrow-down-bold/>
                            </el-icon>
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <!--<el-dropdown-item>修改密码</el-dropdown-item>-->
                                <el-dropdown-item command="tuichu">退出</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="HomeCenter">
            <div class="HomeCenterLeft nui-scroll">
                <el-menu :default-active="defaultActive" @open="handleOpen" @close="handleClose" @select="handleOne"
                         :unique-opened="true">
                    <el-menu-item index="frontPage">
                        <template #title>
                            <!--<span class="iconfont icon-shouye"></span>-->
                            <span style="margin-left: 6px">首页</span>
                        </template>
                    </el-menu-item>
                    <template v-for="item in menuList" :key="item.url">
                        <el-sub-menu :index="item.url">
                            <template #title>
                                <!--<el-icon>-->
                                <!--<FolderOpened/>-->
                                <!--</el-icon>-->
                                <span class="iconfont" :class="item.icon"></span>
                                <span style="margin-left: 6px">{{item.name}}</span>
                            </template>
                            <el-menu-item :index="item2.url" v-for="item2 in item.son" :key="item2.url">
                                <span class="iconfont" :class="item2.icon"></span>
                                <span style="margin-left: 6px">{{item2.name}}</span>
                            </el-menu-item>
                        </el-sub-menu>
                    </template>

                    <!--<template v-for="item in menuList" :key="item.url">-->
                    <!--<template v-if="item.sort == '1'">-->
                    <!--<el-menu-item :index="item.url">-->
                    <!--<el-icon>-->
                    <!--<Menu/>-->
                    <!--</el-icon>-->
                    <!--<template #title>{{item.name}}</template>-->
                    <!--</el-menu-item>-->
                    <!--</template>-->
                    <!--<template v-if="item.sort == '2'">-->
                    <!--<el-sub-menu :index="item.url">-->
                    <!--<template #title>-->
                    <!--<el-icon>-->
                    <!--<FolderOpened/>-->
                    <!--</el-icon>-->
                    <!--<span>{{item.name}}</span>-->
                    <!--</template>-->
                    <!--<el-menu-item :index="item2.url" v-for="item2 in item.children" :key="item2.url">-->
                    <!--<el-icon>-->
                    <!--<Menu/>-->
                    <!--</el-icon>-->
                    <!--{{item2.name}}-->
                    <!--</el-menu-item>-->
                    <!--</el-sub-menu>-->
                    <!--</template>-->
                    <!--</template>-->
                </el-menu>
            </div>
            <div class="HomeCenterRight">
                <div class="HomeCenterRightIn">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {ArrowDownBold} from '@element-plus/icons'

    import {businessGet} from '../api/business.js'

    import Global from '../Global.js'

    export default {
        name: 'Home',
        components: {
            ArrowDownBold
        },
        data() {
            return {
                userInfo: null,
                schoolLogoUrl: '',
                schoolName: '',
                defaultActive: '',
                menuList: [
                    // {
                    //     sort: 1,
                    //     name: '首页',
                    //     url: 'frontPage'
                    // },
                    // {
                    //     sort: 2,
                    //     name: '用户管理',
                    //     url: 'user',
                    //     children: [
                    //         {
                    //             name: '教职工管理',
                    //             url: 'userTeacher'
                    //         },
                    //         {
                    //             name: '学生管理',
                    //             url: 'userStudent'
                    //         },
                    //     ]
                    // },
                    // {
                    //     sort: 2,
                    //     name: '招生管理',
                    //     url: 'recruit',
                    //     children: [
                    //         {
                    //             name: '招生计划',
                    //             url: 'recruitPlan'
                    //         },
                    //         {
                    //             name: '招生老师',
                    //             url: 'recruitTeacher'
                    //         },
                    //         {
                    //             name: '招生排名',
                    //             url: 'recruitRanking'
                    //         },
                    //     ]
                    // },
                    // {
                    //     sort: 2,
                    //     name: '报名管理',
                    //     url: 'signup',
                    //     children: [
                    //         {
                    //             name: '报名流程',
                    //             url: 'signupProcess'
                    //         },
                    //     ]
                    // },
                    // {
                    //     sort: 2,
                    //     name: '缴费管理',
                    //     url: 'pay',
                    //     children: [
                    //         {
                    //             name: '应收项目',
                    //             url: 'payReceivable'
                    //         },
                    //         {
                    //             name: '类别管理',
                    //             url: 'paySort'
                    //         },
                    //         {
                    //             name: '已缴查询',
                    //             url: 'payPaid'
                    //         },
                    //         {
                    //             name: '退款查询',
                    //             url: 'payRefund'
                    //         },
                    //         {
                    //             name: '待缴查询',
                    //             url: 'payPending'
                    //         },
                    //         {
                    //             name: '数据汇总',
                    //             url: 'paySummary'
                    //         },
                    //         {
                    //             name: '项目报表',
                    //             url: 'payProjectReport'
                    //         },
                    //         {
                    //             name: '部门报表',
                    //             url: 'payDepartmentReport'
                    //         },
                    //     ]
                    // },
                    //
                    // {
                    //
                    //     sort: 2,
                    //     name: '系统配置',
                    //     url: 'system',
                    //     children: [
                    //         {
                    //             name: '基础信息',
                    //             url: 'systemSchool',
                    //         },
                    //         {
                    //             name: '导入记录',
                    //             url: 'systemImport',
                    //         },
                    //         {
                    //             name: '预约下载',
                    //             url: 'systemDown',
                    //         },
                    //         {
                    //             name: '学生信息',
                    //             url: 'systemStudent',
                    //         },
                    //         {
                    //             name: '操作记录',
                    //             url: 'systemOperate',
                    //         },
                    //         {
                    //             name: '权限管理',
                    //             url: 'systemPermission',
                    //         },
                    //         {
                    //             name: '基础配置',
                    //             url: 'systemAddRouter',
                    //         },
                    //     ]
                    // },
                ],
                allowPage: [],
            }
        },
        watch: {
            $route(to) {
                var href = to.path.substring(1, to.path.length);
                if (this.allowPage.indexOf(href) == -1) {
                    this.$router.push('/')
                    this.defaultActive = '';
                    this.$message({
                        showClose: true,
                        message: '暂无权限',
                        type: 'warning'
                    });
                }
                this.$nextTick(() => {
                    if (this.$route.path != '/') {
                        this.defaultActive = to.path.substring(1);
                    }
                })
            }
        },
        created() {
            this.userInfo = JSON.parse(localStorage.getItem(Global.GlobalUserInfoLocalName));
            document.title = this.userInfo.school_name + 'v' + Global.GlobalVersion;
            this.schoolLogoUrl = this.userInfo.logo;
            this.schoolName = this.userInfo.school_name;
            this.getMenuList();
        },
        methods: {
            jumpPage() {
                this.$router.push('/systemDown');
            },
            getMenuList() {
                let url = '/admin/v1/menu';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.menuList = res.data.data;
                        let allowPage = ['frontPage', 'login', 'systemAddRouter'];
                        res.data.data.forEach(item => {
                            if (item.son && item.son.length > 0) {
                                item.son.forEach(item2 => {
                                    allowPage.push(item2.url)
                                })
                            }
                        })
                        this.allowPage = allowPage;
                        this.$nextTick(() => {
                            this.defaultActive = this.$route.path.substring(1);
                        })
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            loginOut() {
                localStorage.removeItem(Global.GlobalUserInfoLocalName);
                this.$router.push('/login');
                setTimeout(() => {
                    location.reload();
                }, 500)
            },
            handleCommand(command) {
                if (command == 'tuichu') {
                    this.loginOut();
                }
            },
            handleOne(key) {
                this.$router.push("/" + key);
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath)
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath)
            },
        },
    }
</script>

<style scoped>

    .Home {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 70px;
        position: relative;
    }

    .HomeTop {
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .HomeTopLeft {
        width: 500px;
        height: 100%;
        box-sizing: border-box;
        float: left;
        position: relative;
    }

    .HomeTopLeftImg {
        font-size: 0;
        position: absolute;
        top: 50%;
        left: 26px;
        transform: translateY(-50%);
    }

    .HomeTopLeftText {
        font-size: 0;
        position: absolute;
        top: 50%;
        left: 74px;
        transform: translateY(-50%);
    }

    .HomeTopLeftText > span {
        font-size: 18px;
        color: rgba(112, 112, 112, 1);
        font-weight: bold;
    }

    .HomeTopRight {
        height: 100%;
        box-sizing: border-box;
        padding: 10px 16px;
        float: right;
    }

    .HomeTopRightText {
        height: 100%;
        font-size: 0;
        line-height: 40px;
        float: left;
        margin-left: 10px;
    }

    .HomeTopRightImg {
        width: 34px;
        height: 34px;
        font-size: 0;
        border-radius: 50%;
        float: left;
        margin-left: 10px;
        margin-top: 3px;
    }

    .HomeCenter {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-left: 170px;
        position: relative;
    }

    .HomeCenterLeft {
        width: 160px;
        height: 100%;
        box-sizing: border-box;
        padding: 8px 0;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        overflow: hidden;
        overflow-y: auto;
    }

    .HomeCenterRight {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0 10px 10px 0;
    }

    .HomeCenterRightIn {
        width: 100%;
        height: 100%;
    }

    /*滚动条*/
    .nui-scroll::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    .nui-scroll::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, .05);
        border-radius: 10px;
        -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1);
    }

    /*鼠标悬浮在该类指向的控件上时滑块的样式*/
    .nui-scroll:hover::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, .2);
        border-radius: 10px;
        -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1);
    }

    /*鼠标悬浮在滑块上时滑块的样式*/
    .nui-scroll::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, .4);
        -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1);
    }

    /*正常时候的主干部分*/
    .nui-scroll::-webkit-scrollbar-track {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: white;
    }

    /*鼠标悬浮在滚动条上的主干部分*/
    .nui-scroll::-webkit-scrollbar-track:hover {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .4);
        background-color: rgba(0, 0, 0, .01);
    }

</style>

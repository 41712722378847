<template>
    <div class="baseMain">
        <el-skeleton :rows="5" animated v-if="!show"/>
        <div class="menuMain" v-if="show">
            <div class="systemCustomMenu">
                <div class="systemCustomMenuBottom">
                    <div class="systemCustomMenuBottomIn">
                        <div class="systemCustomMenuBottomOne pre_menu_link"
                             :class="{'bottomOneActive' : index == menuActive}" :style="bottomOneStyle"
                             v-for="(item,index) in menuList" :key="index" @click="menuSelOne(item,index)">
                            <div class="systemCustomMenuBottomOneIn">
                                <div class="systemCustomMenuBottomOneFont line-limit-length">
                                    <el-icon color="#C1C1C1" :size="12" style="margin-right: 2px;"
                                             v-if="item['sub_button'] && item['sub_button'].length > 0">
                                        <List/>
                                    </el-icon>
                                    <span>{{item.name}}</span>
                                </div>
                                <div class="systemCustomMenuBottomOneSub" v-if="index == menuActive">
                                    <div class="systemCustomMenuBottomOneSubOne"
                                         :class="{'bottomOneActive' : sonIndex == subMenuActive}"
                                         v-for="(son,sonIndex) in item['sub_button']" :key="'son'+index+sonIndex"
                                         @click.stop="subMenuSelOne(son,sonIndex)">
                                        <div class="systemCMBOneSubOneFont line-limit-length">
                                            <span>{{son.name}}</span>
                                        </div>
                                    </div>
                                    <div class="systemCustomMenuBottomOneSubOne"
                                         v-if="!(item['sub_button']) || item['sub_button'].length<5">
                                        <div class="menuAdd" @click.stop="addNewSubMenu">
                                            <el-icon class="menuAddIcon" color="#C1C1C1" :size="20">
                                                <Plus/>
                                            </el-icon>
                                        </div>
                                    </div>
                                    <el-icon class="subIcon" color="#fafafa" :size="16">
                                        <CaretBottom/>
                                    </el-icon>
                                </div>
                            </div>
                        </div>
                        <div class="systemCustomMenuBottomOne pre_menu_link" :style="bottomOneStyle"
                             v-if="menuList.length < 3">
                            <div class="menuAdd" @click="addNewMenu">
                                <el-icon class="menuAddIcon" color="#C1C1C1" :size="20">
                                    <Plus/>
                                </el-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-button type="danger" class="menuCleanAll" @click="cleanAll">全部清空并发布</el-button>
            <div class="menuMainIn nui-scroll" v-if="selMsg != null">
                <el-icon class="menuMainInIcon" color="#F4F5F9" :size="40">
                    <CaretLeft/>
                </el-icon>
                <div class="menuMainInTop">
                    <div class="menuMainInTopLeft">
                        <span>{{selMsg.name}}</span>
                    </div>
                    <div class="menuMainInTopRight" @click="delMenu">
                        <span>删除菜单</span>
                    </div>
                </div>
                <div class="menuMainInForm">
                    <el-form label-width="86px" label-position="left">
                        <el-form-item label="菜单名称">
                            <el-input v-model="selMsg.name" style="width: 200px;"></el-input>
                        </el-form-item>
                        <el-form-item label=" ">
                            <span v-if="subMenuActive == null">仅支持中英文和数字，字数不超过4个汉字或8个字母</span>
                            <span v-else>仅支持中英文和数字，字数不超过8个汉字或16个字母</span>
                        </el-form-item>
                        <template v-if="!(selMsg['sub_button'] && selMsg['sub_button'].length>0)">
                            <el-form-item label="菜单内容">
                                <el-radio-group v-model="selMsg.type">
                                    <el-radio label="view">跳转网页</el-radio>
                                    <el-radio label="article_id">发布内容</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label=" ">
                                <el-card class="box-card" style="width: 100%;">
                                    <template v-if="selMsg.type == 'view'">
                                        <el-form-item label=" ">
                                            订阅者点击该子菜单会跳到以下链接
                                        </el-form-item>
                                        <el-form-item label="页面地址">
                                            <el-input v-model="selMsg.url" style="width: 500px;"></el-input>
                                        </el-form-item>
                                    </template>
                                    <template v-if="selMsg.type == 'article_id'">
                                        <el-form-item label="内容编号">
                                            <el-input v-model="selMsg.article_id" style="width: 500px;"
                                                      readonly></el-input>
                                        </el-form-item>
                                        <div class="menuArticle">
                                            <div class="menuArticleLeft">
                                                <el-divider>当前选择</el-divider>
                                                <el-card :body-style="{ padding: '0px' }" v-if="articleMsg != null">
                                                    <img :src="articleMsg.thumb_url" class="image"/>
                                                    <div style="padding: 14px;">
                                                        <span>{{articleMsg.title}}</span>
                                                        <div class="bottom">
                                                            <time class="time">{{articleMsg.author}}&nbsp;&nbsp;{{articleMsg.update_time}}</time>
                                                            <el-button type="text" class="button"
                                                                       @click="lookMsg(articleMsg.url)">内容预览
                                                            </el-button>
                                                        </div>
                                                    </div>
                                                </el-card>
                                                <el-empty v-else description="请在右侧列表选择要使用的文章"></el-empty>
                                            </div>
                                            <div class="menuArticleRight">
                                                <publicTable :publicTableOtherHeight="searchHeight"
                                                             :publicTableData="tableData"
                                                             v-if="selMsg.type == 'article_id'"
                                                             style="margin-top: 10px">
                                                    <!--<el-table-column prop="article_id" label="内容编号"></el-table-column>-->
                                                    <el-table-column prop="title" label="标题"></el-table-column>
                                                    <el-table-column prop="author" label="作者"></el-table-column>
                                                    <el-table-column label="封面图">
                                                        <template #default="scope">
                                                            <el-image v-if="scope.row.thumb_url"
                                                                      style="width: 80px;height: 80px;"
                                                                      :src="scope.row.thumb_url"
                                                                      fit="contain"
                                                                      :preview-src-list="[scope.row.thumb_url]"
                                                                      :z-index="20000"
                                                                      :append-to-body="true"></el-image>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="内容预览">
                                                        <template #default="scope">
                                                            <el-link type="primary" @click="lookMsg(scope.row.url)">点击预览
                                                            </el-link>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="update_time"
                                                                     label="上次修改时间"></el-table-column>
                                                    <el-table-column label="操作" width="100">
                                                        <template #default="scope">
                                                            <el-link type="success"
                                                                     @click.prevent="selOneMsg(scope.row.article_id)">
                                                                使用该文章
                                                            </el-link>
                                                        </template>
                                                    </el-table-column>
                                                </publicTable>
                                                <div class="contentPage">
                                                    <el-pagination style="float: right;" background
                                                                   @current-change="handleCurrentChange"
                                                                   :current-page="page" :page-size="10"
                                                                   layout="total, prev, pager, next"
                                                                   :total="total"></el-pagination>
                                                    <!--<el-pagination layout="prev, pager, next" :current-page="page"-->
                                                    <!--:total="50" :page-size="10" :hide-on-single-page="true"></el-pagination>-->

                                                    <!--<el-pagination layout="prev, pager, next" :total="100"-->
                                                    <!--:page-size="10"></el-pagination>-->
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </el-card>
                            </el-form-item>
                        </template>
                    </el-form>
                </div>
                <div class="menuMainInBottom">
                    <el-button type="success" @click="menuSave" style="width: 200px;">保存并发布</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {Plus, CaretBottom, CaretLeft, List} from '@element-plus/icons'

    import {ref, watch} from 'vue'

    import {businessGet, businessPost} from '../../api/business.js'

    import {ElMessage, ElMessageBox} from 'element-plus'

    import publicTable from '../../components/publicTable.vue'

    export default {
        name: "systemCustomMenu",
        components: {
            Plus,
            CaretBottom,
            CaretLeft,
            List,
            publicTable
        },
        setup() {
            const menuList = ref([])
            const menuActive = ref(null)
            const menuSelMsg = ref(null)
            const selMsg = ref(null)
            const subMenuActive = ref(null)
            const bottomOneStyle = ref({
                width: "100%"
            })
            const show = ref(false)
            const searchHeight = ref(360)
            const tableData = ref([])
            const page = ref(1)
            const total = ref(0)

            watch(menuList, () => {
                let menuNum = menuList.value.length;
                if (menuNum < 3) {
                    menuNum += 1
                }
                let widthNum = (100 / menuNum).toFixed(2)
                let num = Math.floor(widthNum * 100) / 100
                bottomOneStyle.value = {
                    width: num + '%'
                }
            }, {deep: true});

            watch(selMsg, (val) => {
                if (val && val.type == 'article_id' && val.article_id) {
                    getArticleMsg(val.article_id)
                }
                else {
                    articleMsg.value = null;
                }
            }, {deep: true})

            const subMenuSelOne = (item, index) => {
                subMenuActive.value = index;
                selMsg.value = item;
            }

            const addNewSubMenu = () => {
                if (!(menuSelMsg.value['sub_button'])) {
                    menuSelMsg.value['sub_button'] = [];
                }
                menuSelMsg.value['sub_button'].push({
                    "type": "view",
                    "name": "子菜单名称",
                    "url": "",
                    "article_id": "",
                });
                subMenuSelOne(menuSelMsg.value['sub_button'][menuSelMsg.value['sub_button'].length - 1], (menuSelMsg.value['sub_button'].length - 1))
            }

            const menuSelOne = (item, index) => {
                subMenuActive.value = null;
                menuActive.value = index;
                menuSelMsg.value = item;
                selMsg.value = menuSelMsg.value;
            }

            const addNewMenu = () => {
                menuList.value.push({
                    "type": "click",
                    "name": "菜单名称",
                    "url": "",
                    "article_id": "",
                })
                menuSelOne(menuList.value[(menuList.value.length - 1)], (menuList.value.length - 1))
            }

            const menuSave = () => {
                let url = '/common/v1/wechat/menu';
                let data = {
                    button: menuList.value
                }
                console.log(JSON.stringify(data));
                businessPost(url, data).then(res => {
                    if (res.data.status == 200) {
                        ElMessage({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        getMsg()
                    }
                    else {
                        ElMessage({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }

            const delMenu = () => {
                if (subMenuActive.value != null) {
                    menuSelMsg.value['sub_button'].splice(subMenuActive.value, 1);
                    if (menuSelMsg.value['sub_button'].length > 0) {
                        subMenuSelOne(menuSelMsg.value['sub_button'][menuSelMsg.value['sub_button'].length - 1], (menuSelMsg.value['sub_button'].length - 1))
                    }
                    else {
                        menuSelOne(menuList.value[menuActive.value], menuActive.value)
                    }
                }
                else {
                    menuList.value.splice(menuActive.value, 1);
                    if (menuList.value.length > 0) {
                        menuSelOne(menuList.value[(menuList.value.length - 1)], (menuList.value.length - 1))
                    }
                    else {
                        selMsg.value = null;
                    }
                }
            }

            const getMsg = () => {
                show.value = false;
                let url = '/common/v1/wechat/menu/list';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        show.value = true;
                        menuActive.value = null;
                        menuSelMsg.value = null;
                        selMsg.value = null;
                        subMenuActive.value = null;
                        menuList.value = [];
                        if (res.data.data.is_menu_open == 1) {
                            let list = res.data.data.selfmenu_info.button
                            list.forEach(item => {
                                if (item.sub_button) {
                                    item.sub_button = item.sub_button.list
                                }
                            })
                            menuList.value = list;
                        }
                    }
                    else {
                        ElMessage({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }

            const cleanAll = () => {
                ElMessageBox.confirm('此操作将清空全部菜单并立即生效, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/wechat/menu';
                    let data = {
                        button: []
                    }
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            ElMessage({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            getMsg()
                        }
                        else {
                            ElMessage({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            }
            const getTableData = () => {
                tableData.value = [];
                let url = '/common/v1/wechat/freepublish/list?page=' + page.value
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        tableData.value = res.data.data.list
                        total.value = res.data.data.count
                    }
                    else {
                        ElMessage({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }

            const handleCurrentChange = (val) => {
                page.value = val;
                getTableData();
            }

            const lookMsg = (val) => {
                window.open(val)
            }

            const selOneMsg = (val) => {
                selMsg.value.article_id = val;
            }

            const articleMsg = ref(null)

            const getArticleMsg = (article_id) => {
                let url = '/common/v1/wechat/freepublish/info?article_id=' + article_id
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        articleMsg.value = res.data.data
                    }
                    else {
                        ElMessage({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }

            return {
                menuList,
                menuActive,
                menuSelMsg,
                selMsg,
                subMenuActive,
                bottomOneStyle,
                subMenuSelOne,
                addNewSubMenu,
                menuSelOne,
                addNewMenu,
                menuSave,
                delMenu,
                getMsg,
                show,
                cleanAll,
                searchHeight,
                tableData,
                page,
                total,
                getTableData,
                handleCurrentChange,
                lookMsg,
                selOneMsg,
                getArticleMsg,
                articleMsg
            };
        },
        // data() {
        //     return {
        //         // menuList: [],
        //         // menuActive: null,
        //         // menuSelMsg: null,
        //         // selMsg: null,
        //         // subMenuActive: null,
        //         // bottomOneStyle: {
        //         //     width: "100%"
        //         // },
        //     }
        // },
        // watch: {
        //     menuList() {
        //         let menuNum = this.menuList.length;
        //         if (menuNum < 3) {
        //             menuNum += 1
        //         }
        //         let widthNum = (100 / menuNum).toFixed(2)
        //         let num = Math.floor(widthNum * 100) / 100
        //         this.bottomOneStyle = {
        //             width: num + '%'
        //         }
        //     }
        // },
        created() {
            this.getMsg();
            this.getTableData();
            // this.menuList = [
            //     {
            //         "type": "click",
            //         "name": "今日歌曲",
            //         "key": "V1001_TODAY_MUSIC"
            //     },
            //     {
            //         "name": "菜单",
            //         "sub_button": [
            //             {
            //                 "type": "view",
            //                 "name": "搜索",
            //                 "url": "http://www.soso.com/"
            //             },
            //             {
            //                 "type": "miniprogram",
            //                 "name": "wxa",
            //                 "url": "http://mp.weixin.qq.com",
            //                 "appid": "wx286b93c14bbf93aa",
            //                 "pagepath": "pages/lunar/index"
            //             },
            //             {
            //                 "type": "click",
            //                 "name": "赞一下我们",
            //                 "key": "V1001_GOOD"
            //             }
            //         ]
            //     },
            // ]
        },
        // methods: {
        //     subMenuSelOne(item, index) {
        //         this.subMenuActive = index;
        //         this.selMsg = item;
        //     },
        //     addNewSubMenu() {
        //         console.log(this.menuSelMsg);
        //         if (!(this.menuSelMsg['sub_button'])) {
        //             this.menuSelMsg['sub_button'] = [];
        //         }
        //         this.menuSelMsg['sub_button'].push({
        //             "type": "view",
        //             "name": "",
        //             "url": ""
        //         });
        //         this.subMenuSelOne(this.menuSelMsg['sub_button'][this.menuSelMsg['sub_button'].length - 1], (this.menuSelMsg['sub_button'].length - 1))
        //     },
        //     menuSelOne(item, index) {
        //         this.menuActive = index;
        //         this.menuSelMsg = item;
        //         this.selMsg = this.menuSelMsg;
        //     },
        //     addNewMenu() {
        //         this.menuList.push({
        //             "type": "click",
        //             "name": "",
        //         })
        //         this.menuSelOne(this.menuList[(this.menuList.length - 1)], (this.menuList.length - 1))
        //     },
        // }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .menuMain {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-left: 338px;
        position: relative;
        background-color: #fff;
    }

    .systemCustomMenu {
        width: 318px;
        height: 580px;
        box-sizing: border-box;
        background: transparent url(../../assets/images/zidingyicaidan.png) no-repeat 0 0;
        background-position: 0 0;
        border: 1px solid #e7e7eb;
        position: absolute;
        top: 0;
        left: 0;
    }

    .systemCustomMenuBottom {
        width: 100%;
        height: 51px;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        bottom: 0;
        border-top: 1px solid #e7e7eb;
        background: transparent url(../../assets/images/caidandibu.png) no-repeat 0 0;
        background-position: 0 0;
        background-repeat: no-repeat;
        padding-left: 43px;
    }

    .systemCustomMenuBottomIn {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .systemCustomMenuBottomOne {
        height: 100%;
        box-sizing: border-box;
        float: left;
        cursor: pointer;
    }

    .pre_menu_link {
        border-left: 1px solid #e7e7eb;
    }

    .systemCustomMenuBottomOneIn {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        color: #616161;
        position: relative;
    }

    .systemCustomMenuBottomOneFont {
        width: 100%;
        height: 100%;
    }

    .systemCustomMenuBottomOneSub {
        width: 100%;
        box-sizing: border-box;
        border-top: 1px solid #e7e7eb;
        border-left: 1px solid #e7e7eb;
        border-right: 1px solid #e7e7eb;
        background-color: #fafafa;
        position: absolute;
        top: -8px;
        left: 0;
        transform: translateY(-100%);
    }

    .systemCustomMenuBottomOneSubOne {
        width: 100%;
        height: 50px;
        box-sizing: border-box;
        padding: 0 6px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        color: #616161;
        border-bottom: 1px solid #e7e7eb;
    }

    .systemCMBOneSubOneFont {
        width: 100%;
        height: 100%;
    }

    .subIcon {
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
    }

    .menuAdd {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
    }

    .menuAddIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .menuCleanAll {
        position: absolute;
        top: 590px;
        left: 90px;
    }

    .menuMainIn {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        position: relative;
        background-color: #F4F5F9;
        padding: 40px 16px;
        border: 1px solid #E7E7EB;
        position: relative;
        overflow-y: auto;
    }

    .menuMainInIcon {
        position: absolute;
        top: 534px;
        left: -24px;
    }

    .menuMainInTop {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        padding: 0 16px;
        line-height: 40px;
        border-bottom: 1px solid #E7E7EB;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px;
    }

    .menuMainInBottom {
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        bottom: 4px;
        left: 0;
        text-align: center;
    }

    .menuMainInTopLeft {
        height: 100%;
        float: left;
    }

    .menuMainInTopRight {
        height: 100%;
        float: right;
        color: #F56C6C;
        cursor: pointer;
    }

    .menuMainInForm {
        width: 100%;
        margin-top: 20px;
    }

    .bottomOneActive {
        border: 1px solid #44b549 !important;
        color: #44b549 !important;
        line-height: 48px !important;
    }

    .contentPage {
        width: 100%;
        height: 44px;
        box-sizing: border-box;
        padding-top: 6px;
        text-align: center;
    }

    .menuArticle {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        padding-left: 320px;
    }

    .menuArticleLeft {
        width: 300px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
    }

    .menuArticleRight {
        width: 100%;
        box-sizing: border-box;
    }

    .time {
        font-size: 13px;
        color: #999;
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .button {
        padding: 0;
        min-height: auto;
    }

    .image {
        width: 100%;
        display: block;
    }

</style>

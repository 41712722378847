//引入网络请求框架
import axios from 'axios';

import {ElMessageBox, ElLoading} from 'element-plus';
//
import Global from '../../Global.js';

import router from "../../router/index.js";

// 创建一个 axios 实例
const service = axios.create({
    timeout: 60000 // 请求超时时间
})

service.interceptors.request.use((config) => {
    return config;
}, (error) => {
    return Promise.reject(error);
});

service.defaults.withCredentials = true;

service.interceptors.response.use(
    response => {
        let data = response.data;
        switch (data.status) {
            case 401:
                localStorage.removeItem(Global.GlobalUserInfoLocalName);
                router.replace({
                    path: '/login',
                })
                break;
            default:
                return response;
        }
    },
    error => {
        let message = JSON.parse(JSON.stringify(error));
        ElMessageBox.confirm(message.message, '提示', {
            confirmButtonText: '刷新试试',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            location.reload();
        }).catch(() => {
            let loadingInstance = ElLoading.service({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0)'
            });
            loadingInstance.close();
        })
        return Promise.reject(error);
    }
);

export default service

import request from '../plugin/axios/index.js'
import Global from "../Global";

export function businessGet(url) {
    let token = localStorage.getItem(Global.GlobalTokenLocalName);
    return request({
        url: process.env.VUE_APP_API_URL + url,
        headers:{
            'Authorization':token
        },
        method: 'get',
    })
}

export function businessPost(url, data) {
    let msg = data;
    let token = localStorage.getItem(Global.GlobalTokenLocalName);
    return request({
        url: process.env.VUE_APP_API_URL + url,
        headers:{
            'Authorization':token
        },
        method: 'post',
        data: msg,
    })
}

export function businessPut(url, data) {
    let msg = data;
    let token = localStorage.getItem(Global.GlobalTokenLocalName);
    return request({
        url: process.env.VUE_APP_API_URL + url,
        headers:{
            'Authorization':token
        },
        method: 'put',
        data: msg,
    })
}

export function businessDel(url, data) {
    let msg = data;
    let token = localStorage.getItem(Global.GlobalTokenLocalName);
    return request({
        url: process.env.VUE_APP_API_URL + url,
        headers:{
            'Authorization':token
        },
        method: 'delete',
        data: msg,
    })
}

<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>项目名称：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.project_name" placeholder="支持模糊搜索" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>项目状态：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.status" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in statusList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>项目类别：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.cate_id" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in cateList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openImport" v-if="authorityStatus.projectImport.show">批量创建项目
                </el-button>
            </div>
            <div class="baseOperateOne" style="margin-right: 10px;">
                <el-button type="primary" @click="openAddEdit('new')" v-if="authorityStatus.projectNew.show">新建项目
                </el-button>
            </div>
            <div class="baseOperateOne" style="margin-right: 10px;">
                <el-button type="primary" @click="openQrcode">收款二维码</el-button>
            </div>
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                         ref="publicTableUserStudent">
                <el-table-column prop="explicit_id" label="项目ID"></el-table-column>
                <el-table-column prop="is_signup_name" label="费用类型"></el-table-column>
                <el-table-column prop="name" label="项目名称"></el-table-column>
                <el-table-column prop="cates.name" label="项目类别"></el-table-column>
                <el-table-column prop="explains" label="项目说明"></el-table-column>
                <el-table-column prop="end_time" label="项目截止时间" width="110"></el-table-column>
                <el-table-column prop="typeName" label="类型"></el-table-column>
                <el-table-column prop="price" label="报名缴费金额"></el-table-column>
                <el-table-column prop="statusName" label="状态"></el-table-column>
                <el-table-column label="是否导入收款用户">
                    <template #default="scope">
                        <template v-if="scope.row.is_signup == 0">
                            <span v-if="scope.row.receive_count == 0">未导入</span>
                            <span v-else>已导入</span>
                        </template>
                        <template v-else>
                            <span>无需导入</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary"
                                 @click.prevent="openAddEdit('edit',scope.row)" v-if="authorityStatus.projectEdit.show">
                            编辑基本信息
                        </el-link>
                        <el-link class="tableButton" type="primary"
                                 @click.prevent="openImportUser(scope.row)"
                                 v-if="scope.row.is_signup == 0 && authorityStatus.receiveImport.show">
                            添加收款用户
                        </el-link>
                        <el-link class="tableButton" type="primary"
                                 @click.prevent="openUserDetail(scope.row)"
                                 v-if="scope.row.is_signup == 0 && authorityStatus.receive.show">
                            查看收款用户
                        </el-link>
                        <el-link class="tableButton" type="success"
                                 v-if="scope.row.status != 1 && authorityStatus.projectChange.show"
                                 @click.prevent="openRelease(scope.row)">
                            发布项目
                        </el-link>
                        <el-link class="tableButton" type="primary"
                                 v-if="scope.row.status != 0 && authorityStatus.projectChange.show"
                                 @click.prevent="openFreeze(scope.row)">
                            冻结项目
                        </el-link>
                        <el-link class="tableButton" type="danger" @click.prevent="openDel(scope.row)"
                                 v-if="authorityStatus.projectDelete.show">
                            删除
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <el-dialog title="收款二维码" v-model="dialogVisible" width="800px">
            <div style="width: 100%;text-align: center;">
                <div class="payBackGround" ref="imageTofile">
                    <img src="../../assets/images/payBackGround.png" alt="">
                    <img class="xuesheng" src="/web_img/paySearch.png" alt="">
                    <div class="schoolName">
                        <span>{{userInfo.school_name}}</span>
                    </div>
                </div>
            </div>
            <!--<el-image :src="require('../../assets/images/学生缴费查询.png')"-->
            <!--fit="contain"></el-image>-->
            <!--<vue-qrcode value="Hello, World!" :options="{ width: 200 }"></vue-qrcode>-->
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="downImage">下载图片</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="addEditTitle" v-model="addEditVisible" width="600px" :destroy-on-close="true">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
                <el-form-item label="费用类型">
                    <el-radio-group v-model="ruleForm.is_signup">
                        <el-radio :label="0">普通项目</el-radio>
                        <el-radio :label="1">报名项目</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="项目名称" prop="name">
                    <el-input v-model="ruleForm.name" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="项目类别" prop="cate">
                    <el-select v-model="ruleForm.cate" placeholder="请选择" style="width: 300px;">
                        <el-option v-for="item in cateList" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否允许部分缴费" v-if="ruleForm.is_signup == 0">
                    <el-radio-group v-model="ruleForm.part_payment">
                        <el-radio :label="1">允许</el-radio>
                        <el-radio :label="0">不允许</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="项目说明">
                    <el-input type="textarea" v-model="ruleForm.explains" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="项目截止时间">
                    <el-date-picker v-model="ruleForm.end_time" type="date" placeholder="选择日期"
                                    value-format="YYYY-MM-DD" style="width: 300px;"></el-date-picker>
                    <!--<el-date-picker v-model="ruleForm.end_time" type="datetime" placeholder="选择日期时间"-->
                    <!--value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>-->
                </el-form-item>
                <el-form-item label="项目类型" v-if="ruleForm.is_signup == 0">
                    <el-radio-group v-model="ruleForm.type">
                        <el-radio :label="1">选缴</el-radio>
                        <el-radio :label="2">必缴</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="报名缴费金额" v-if="ruleForm.is_signup == 1">
                    <el-input-number v-model="ruleForm.price" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveAddPlan">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="批量创建项目" v-model="importDialog" width="600px">
            <el-form :model="importForm" :rules="importRules" ref="importForm" label-width="auto">
                <el-form-item label="批量创建项目模板">
                    <a href="./files/批量创建项目模板.xlsx" class="downMuban"
                       style="float: left;" download="批量创建项目模板.xlsx">点击下载模板</a>
                </el-form-item>
                <el-form-item label="上传文件" prop="importUploadList">
                    <el-upload style="width: 400px;" ref="importUpload"
                               action="https://jsonplaceholder.typicode.com/posts/"
                               :on-change="importHandleChange" :before-remove="importHandleChange"
                               :file-list="importForm.importFileList"
                               :auto-upload="false" :on-exceed="importHandleExceed"
                               list-type="text" accept=".xlsx" :limit="importForm.importFileLimit">
                        <el-button type="primary">选取文件</el-button>
                        <template #tip>
                            <div class="el-upload__tip">请编辑上一项下载的模板，然后上传</div>
                        </template>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveImport">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="导入收款用户" v-model="userDialog" width="1000px" :close-on-click-modal="false"
                   :before-close="confirmClose">
            <div style="width: 100%;padding-bottom: 20px;">
                <el-steps :active="active" align-center>
                    <el-step title="导入收款用户" description=""></el-step>
                    <el-step title="确认收款用户和缴费金额" description=""></el-step>
                    <el-step title="完成" description=""></el-step>
                </el-steps>
            </div>
            <template v-if="active == 1">
                <el-form :model="userForm" ref="userForm" label-width="200px">
                    <el-form-item label="">
                        <el-radio-group v-model="userForm.type">
                            <el-radio label="1">选择组织部门</el-radio>
                            <el-radio label="2">自定义用户金额</el-radio>
                            <el-radio label="3">选择单个用户</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <template v-if="userForm.type == '1'">
                        <el-form-item label="缴费班级">
                            <div style="width: 100%;">
                                <el-cascader ref="jzgxsTree" v-model="org_ids" :options="options" :props="props"
                                             clearable></el-cascader>
                            </div>
                            <!--<div style="width: 100%;">-->
                            <!--<el-input style="width: 300px;" placeholder="请输入要搜索的内容" suffix-icon="el-icon-search"-->
                            <!--v-model="filterText" clearable></el-input>-->
                            <!--</div>-->
                            <!--<div style="width: 100%;margin-top: 10px;">-->
                            <!--<el-tree ref="jzgxsTree" :data="detailTreeData" node-key="id" default-expand-all-->
                            <!--:expand-on-click-node="false" :highlight-current="true" show-checkbox-->
                            <!--:props="defaultProps" :filter-node-method="filterNode">-->
                            <!--<template #default="{ node}">-->
                            <!--<div class="custom-tree-node">-->
                            <!--<span>{{ node.label }}</span>-->
                            <!--</div>-->
                            <!--</template>-->
                            <!--</el-tree>-->
                            <!--&lt;!&ndash;<el-tree ref="tree" :data="detailTreeData" node-key="id" default-expand-all&ndash;&gt;-->
                            <!--&lt;!&ndash;:expand-on-click-node="false" :highlight-current="true" show-checkbox&ndash;&gt;-->
                            <!--&lt;!&ndash;:filter-node-method="filterNode">&ndash;&gt;-->
                            <!--&lt;!&ndash;<template #default="{ node}">&ndash;&gt;-->
                            <!--&lt;!&ndash;<div class="custom-tree-node">&ndash;&gt;-->
                            <!--&lt;!&ndash;<span>{{ node.label }}</span>&ndash;&gt;-->
                            <!--&lt;!&ndash;</div>&ndash;&gt;-->
                            <!--&lt;!&ndash;</template>&ndash;&gt;-->
                            <!--&lt;!&ndash;</el-tree>&ndash;&gt;-->
                            <!--</div>-->
                        </el-form-item>
                        <el-form-item label="应缴金额">
                            <el-input-number v-model="userForm.price" controls-position="right"
                                             :min="0"></el-input-number>
                        </el-form-item>
                    </template>
                    <template v-if="userForm.type == '2'">
                        <el-form-item label="自定义用户金额模板">
                            <a href="./files/批量设置现金收款模板.xlsx" class="downMuban"
                               style="float: left;" download="批量设置现金收款模板.xlsx">点击下载模板</a>
                        </el-form-item>
                        <el-form-item label="上传文件">
                            <el-upload style="width: 400px;" ref="userUpload"
                                       action="https://jsonplaceholder.typicode.com/posts/"
                                       :on-change="userHandleChange" :before-remove="userHandleChange"
                                       :file-list="userForm.userFileList"
                                       :auto-upload="false" :on-exceed="userHandleExceed"
                                       list-type="text" accept=".xlsx" :limit="userForm.userFileLimit">
                                <el-button type="primary">选取文件</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">请编辑上一项下载的模板，然后上传</div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </template>
                    <template v-if="userForm.type == '3'">
                        <el-form-item label="学工号" prop="name">
                            <el-select v-model="value" multiple filterable remote reserve-keyword placeholder="请输入关键词"
                                       :remote-method="remoteMethod" :loading="loading" style="width: 80%;"
                                       size="medium">
                                <el-option v-for="item in options2" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                            <!--<el-input v-model="userForm.user_ids" style="width: 300px;"></el-input>-->
                        </el-form-item>
                        <el-form-item label="应缴金额">
                            <el-input-number v-model="userForm.price" controls-position="right"
                                             :min="0"></el-input-number>
                        </el-form-item>
                    </template>
                </el-form>
            </template>
            <template v-if="active == 2">
                <div style="width: 100%;" v-loading="importLoad" element-loading-text="正在加载人员信息，请稍等"
                     element-loading-background="rgba(255, 255,255, 1)">
                    <el-alert :title="'成功'+importMsg.success+'条，失败'+importMsg.fail+'条'" type="success"></el-alert>
                    <div class="baseOperate">
                        <div class="baseOperateOne">
                            <el-button type="primary" v-if="active == 2" @click="next">确认导入</el-button>
                        </div>
                    </div>
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="importTableData"
                                 v-loading="importTableLoading">
                        <el-table-column prop="card_number" label="学工号"></el-table-column>
                        <el-table-column prop="user_name" label="姓名"></el-table-column>
                        <el-table-column prop="price" label="缴费金额(元)"></el-table-column>
                        <el-table-column prop="date" label="导入状态">
                            <template #default="scope">
                                <span v-if="scope.row.is_success == 1">成功</span>
                                <span v-if="scope.row.is_success == 0">失败</span>
                            </template>
                        </el-table-column>
                    </publicTable>
                    <div class="basePage" style="margin-top: 4px;">
                        <publicPage ref="publicPageImport" :publicPageTotal="{total:importTotal}"
                                    @pageChange="getImportTableData"></publicPage>
                    </div>
                </div>
            </template>
            <template v-if="active == 3">
                <el-result icon="success" title="导入成功" subTitle="请根据提示进行操作">
                    <template #extra>
                        <el-button type="primary" size="medium" @click="userDialog = false">返回</el-button>
                    </template>
                </el-result>
            </template>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" v-if="active == 1" @click="next">下一步</el-button>
                </div>
            </template>
        </el-dialog>
        <el-drawer title="查看收款用户" v-model="drawer" :close-on-click-modal="false" :destroy-on-close="true" size="80%">
            <div class="baseSearch userDetailSearch clearfloat">
                <div class="baseSearchOne">
                    <div class="baseSearchOneLeft">
                        <span>学工号：</span>
                    </div>
                    <div class="baseSearchOneRight">
                        <el-input v-model="userDetailSearchForm.card_number" placeholder="请输入内容"></el-input>
                    </div>
                </div>
                <div class="baseSearchOne">
                    <el-button type="primary" style="margin-left: 20px;" @click="detailSearch">搜索</el-button>
                    <el-button @click="detailCleanAll">清空</el-button>
                </div>
            </div>
            <div class="baseOperate userDetailOperate clearfloat">
                <div class="baseOperateOne" style="margin-right: 10px;">
                    <el-button type="primary" @click="detailExport">导出全部</el-button>
                </div>
            </div>
            <div class="baseTable">
                <publicTable :publicTableOtherHeight="userDetailHeight + 30" :publicTableData="detailTableData"
                             ref="detailTableUserStudent">
                    <el-table-column prop="card_number" label="学工号"></el-table-column>
                    <el-table-column prop="user.name" label="姓名"></el-table-column>
                    <el-table-column prop="price" label="应缴金额"></el-table-column>
                    <el-table-column prop="class_name" label="班级"></el-table-column>
                    <el-table-column prop="project.name" label="项目"></el-table-column>
                    <el-table-column label="操作">
                        <template #default="scope">
                            <el-link class="tableButton" type="primary" @click.prevent="openEditPrice(scope.row)"
                                     v-if="authorityStatus.receivePrice.show">
                                修改应缴金额
                            </el-link>
                            <el-link class="tableButton" type="danger" @click.prevent="openDetailDel(scope.row)"
                                     v-if="authorityStatus.receiveDelete.show">
                                删除
                            </el-link>
                        </template>
                    </el-table-column>
                </publicTable>
            </div>
            <div class="basePage" style="margin-top: 4px;">
                <publicPage ref="detailPageUserStudent" :publicPageTotal="{total:detailTotal}"
                            @pageChange="getDetailTableData"></publicPage>
            </div>
        </el-drawer>
        <el-dialog title="修改应缴金额" v-model="editPriceVisible" width="600px">
            <el-form :model="editPriceForm" ref="ruleForm" label-width="200px">
                <el-form-item label="学工号" prop="name">
                    {{detailSelMsg.card_number}}
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    {{detailSelMsg.user.name}}
                </el-form-item>
                <el-form-item label="项目名称" prop="name">
                    {{selMsg.name}}
                </el-form-item>
                <el-form-item label="当前应缴金额" prop="name">
                    {{detailSelMsg.price}}
                </el-form-item>
                <el-form-item label="已缴金额" prop="name">
                    {{detailSelMsg.received_price}}
                </el-form-item>
                <el-form-item label="新缴费金额" prop="name">
                    <el-input-number v-model="editPriceForm.price" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveEditPrice">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

import publicPage from '../../components/publicPage.vue'

import publicTable from '../../components/publicTable.vue'

import XLSX from 'xlsx'

import {businessGet, businessPost, businessPut, businessDel} from '../../api/business.js'

import {
    utilsUploadFile,
    utilsGetOptionsList,
    utilsGetAuthority,
    utilsExportExcel,
    utilsNotificationJump
} from '../../utils/utils.js'

import Global from '../../Global.js'

import html2canvas from 'html2canvas'

export default {
    name: "payReceivable",
    components: {
        publicPage,
        publicTable
    },
    data() {
        return {
            userInfo: null,
            cateList: [],
            statusList: [],
            searchForm: {
                project_name: '',
                status: '',
                cate_id: '',
            },
            searchHeight: 300,
            tableLoading: false,
            tableData: [],
            total: 0,
            selMsg: null,
            dialogVisible: false,
            ruleForm: {
                name: '',
                cate: '',
                price: 0,
                part_payment: 1,
                explains: '',
                end_time: '',
                type: 1,
                is_signup: 0
            },
            rules: {
                name: [
                    {required: true, message: '该项不能为空', trigger: 'blur'},
                ],
                cate: [
                    {required: true, message: '该项不能为空', trigger: 'blur'},
                ],
            },
            addEditTitle: '',
            addEditVisible: false,
            importDialog: false,
            importForm: {
                importFileList: [],
                importFileLimit: 1,
                importUploadList: [],
            },
            importRules: {
                importUploadList: [
                    {type: 'array', required: true, message: '该项不能为空', trigger: 'blur'}
                ],
            },
            userDialog: false,
            active: 1,
            options: [],
            props: {
                value: 'id',
                label: 'name',
                children: 'son',
                multiple: true
            },
            org_ids: [],
            userForm: {
                type: '1',
                price: 0,
                user_ids: '',
                userFileList: [],
                userFileLimit: 1,
                userUploadList: [],
            },
            submitData: {},
            batch_no: '',
            importLoad: true,
            importMsg: {
                success: 0,
                fail: 0
            },
            filterText: '',
            detailTreeData: [],
            defaultProps: {
                children: 'son',
                label: 'name',
            },
            userDetailDialog: false,
            drawer: false,
            userDetailSearchForm: {
                card_number: '',
            },
            userDetailHeight: 0,
            detailTableData: [],
            detailTotal: 0,
            detailSelMsg: null,
            editPriceVisible: false,
            editPriceForm: {
                price: 0,
            },
            options2: [],
            value: [],
            loading: false,
            authorityStatus: {
                projectNew: {
                    method: 'post',
                    url: '/admin/v1/projects',
                    show: false,
                },
                projectEdit: {
                    method: 'put',
                    url: '/admin/v1/projects/{project_id}',
                    show: false,
                },
                projectDelete: {
                    method: 'delete',
                    url: '/admin/v1/projects/{project_id}',
                    show: false,
                },
                projectImport: {
                    method: 'post',
                    url: '/admin/v1/projects/import',
                    show: false,
                },
                projectChange: {
                    method: 'put',
                    url: '/admin/v1/projects/status/{project_id}',
                    show: false,
                },
                receiveImport: {
                    method: 'post',
                    url: '/admin/v1/receive/import',
                    show: false,
                },
                receive: {
                    method: 'get',
                    url: '/admin/v1/receive',
                    show: false,
                },
                receiveDelete: {
                    method: 'delete',
                    url: '/admin/v1/receive/{receive_id}',
                    show: false,
                },
                receivePrice: {
                    method: 'put',
                    url: '/admin/v1/receive/price/{receive_id}',
                    show: false,
                },
            },
            timer: null,
            importTableLoading: false,
            importTableData: [],
            importTotal: 0,
        }
    },
    watch: {
        filterText(val) {
            this.$refs.jzgxsTree.filter(val);
        },
        'userForm.type'(val) {
            if (val == 1) {
                this.cascaderWidthDealWidth();
            }
        }
    },
    created() {
        this.userInfo = JSON.parse(localStorage.getItem(Global.GlobalUserInfoLocalName));
        this.getSortList();
        this.getStudentTree();
        utilsGetAuthority(this.authorityStatus);
        this.statusList = utilsGetOptionsList('Global_project_status_obj');
        this.$nextTick(() => {
            let baseSearchDom = document.querySelector('.baseSearch');
            let baseOperateDom = document.querySelector('.baseOperate');
            let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
            this.searchHeight = searchHeight;
            this.cleanAll()
        })
    },
    methods: {
        base64ToBlob(code) {
            let parts = code.split(';base64,');
            let contentType = parts[0].split(':')[1];
            let raw = window.atob(parts[1]);
            let rawLength = raw.length;
            let uInt8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], {type: contentType});
        },
        downloadFile(fileName, content) {
            let aLink = document.createElement('a');
            let blob = this.base64ToBlob(content); //new Blob([content]);
            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
            aLink.download = fileName;
            aLink.href = URL.createObjectURL(blob);
            aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
        },
        downImage() {
            // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
            const opts = {
                useCORS: true,
                backgroundColor: null
            }
            html2canvas(this.$refs.imageTofile, opts).then((canvas) => {
                let url = canvas.toDataURL('image/png');
                this.downloadFile('收款二维码.png', url);
            })
        },
        remoteMethod(query) {
            this.timer = null;
            if (query !== '') {
                this.timer = setTimeout(() => {
                    this.loading = true;
                    let url = '/admin/v1/student?type=1&page=1&page_pre=20&key_words=' + query;
                    businessGet(url).then(res => {
                        if (res.data.status == 200) {
                            let tableData = res.data.data.user_list;
                            let newArray = []
                            tableData.forEach(item => {
                                let a = {
                                    value: item.id + '',
                                    label: item.name + '(' + item.card_number + ')'
                                }
                                newArray.push(a)
                            })
                            this.options2 = newArray;
                            this.loading = false
                        }
                    })
                }, 500)
            }
            else {
                this.options2 = []
            }
        },
        openDetailDel(val) {
            this.$confirm('此操作将永久删除该条记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let url = '/admin/v1/receive/' + val.id;
                businessDel(url, {}).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.getDetailTableData();
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }).catch(() => {

            })
        },
        saveEditPrice() {
            let url = '/admin/v1/receive/price/' + this.detailSelMsg.id;
            let data = {
                price: this.editPriceForm.price
            }
            businessPut(url, data).then(res => {
                if (res.data.status == 200) {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'success'
                    });
                    this.editPriceVisible = false;
                    this.getDetailTableData();
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        openEditPrice(val) {
            this.detailSelMsg = val;
            this.editPriceForm.price = 0;
            this.editPriceVisible = true;
        },
        detailExport() {
            let url = '/admin/v1/receive?is_export=1&project_id=' + this.selMsg.id;
            for (let k in this.userDetailSearchForm) {
                if (this.userDetailSearchForm[k]) {
                    url = url + '&' + k + '=' + this.userDetailSearchForm[k];
                }
            }
            utilsExportExcel(url)
        },
        detailCleanAll() {
            this.userDetailSearchForm.card_number = '';
            this.detailSearch();
        },
        detailSearch() {
            this.$refs.detailPageUserStudent.currentPage = 1;
            this.getDetailTableData();
        },
        getDetailTableData() {
            let url = '/admin/v1/receive?project_id=' + this.selMsg.id + '&page=' + this.$refs.detailPageUserStudent.currentPage + '&page_pre=' + this.$refs.detailPageUserStudent.pageSize;
            for (let k in this.userDetailSearchForm) {
                if (this.userDetailSearchForm[k]) {
                    url = url + '&' + k + '=' + this.userDetailSearchForm[k];
                }
            }
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    this.detailTotal = res.data.data.count;
                    let tableData = res.data.data.list;
                    // tableData.forEach(item => {
                    //     item.statusName = this.statusObj[item.status];
                    //     item.typeName = this.typeObj[item.type]
                    // })
                    this.detailTableData = tableData;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        openUserDetail(val) {
            this.drawer = true;
            this.selMsg = val;
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.userDetailSearch');
                let baseOperateDom = document.querySelector('.userDetailOperate');
                let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                this.userDetailHeight = searchHeight;
                this.detailCleanAll()
            })
        },
        getImportTableData() {
            this.importTableData = [];
            let url = '/admin/v1/receive_log?batch_no=' + this.batch_no + '&page=' + this.$refs.publicPageImport.currentPage + '&page_pre=' + this.$refs.publicPageImport.pageSize;
            this.importTableLoading = true;
            businessGet(url).then(res => {
                console.log(res);
                this.importTableLoading = false;
                console.log(123);
                if (res.data.status == 200) {
                    this.importTotal = res.data.data.count;
                    let tableData = res.data.data.list;
                    console.log(tableData);
                    // // tableData.forEach(item => {
                    // //     item.statusName = this.statusObj[item.status];
                    // //     item.typeName = this.typeObj[item.type]
                    // // })
                    this.importTableData = tableData;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        confirmClose(done) {
            if (this.active == 2) {
                this.$confirm('你还未进行确认导入操作, 确定要关闭页面吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    done()
                }).catch(() => {

                })
            }
            else {
                done()
            }
        },
        startPolling() {
            let url = '/admin/v1/receive/import/status?batch_no=' + this.batch_no;
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    let is_complete = res.data.data.is_complete;
                    if (is_complete == 0) {
                        setTimeout(() => {
                            if (this.userDialog) {
                                this.startPolling();
                            }
                        }, 2000)
                    }
                    //处理空数据
                    else if (is_complete == 1 && res.data.data.success == 0 && res.data.data.fail == 0) {
                        this.$message({
                            showClose: true,
                            message: '你的模板为空，请重新导入数据',
                            type: 'warning'
                        });
                        this.openImportUser();
                    }
                    else if (is_complete == 1) {
                        this.importMsg.success = res.data.data.success;
                        this.importMsg.fail = res.data.data.fail;
                        this.$refs.publicPageImport.currentPage = 1;
                        this.importLoad = false;
                        this.getImportTableData();
                    }
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        next() {
            let that = this;
            if (this.active == 1) {
                this.submitData.project_id = this.selMsg.id;
                this.submitData.type = this.userForm.type;
                this.importTableData = [];
                this.importLoad = true;
                if (this.userForm.type == 1) {
                    // let newArray = this.$refs.jzgxsTree.getCheckedKeys();
                    // if (newArray.length == 0) {
                    //     that.$message({
                    //         showClose: true,
                    //         message: '请选择缴费班级',
                    //         type: 'warning'
                    //     });
                    //     return
                    // }

                    let getCheckedNodes = this.$refs.jzgxsTree.getCheckedNodes();
                    if (getCheckedNodes.length == 0) {
                        that.$message({
                            showClose: true,
                            message: '请选择缴费班级',
                            type: 'warning'
                        });
                        return
                    }
                    if (this.org_ids <= 0) {
                        that.$message({
                            showClose: true,
                            message: '请选择缴费班级',
                            type: 'warning'
                        });
                        return
                    }
                    if (this.userForm.price <= 0) {
                        that.$message({
                            showClose: true,
                            message: '缴费金额必须大于0',
                            type: 'warning'
                        });
                        return
                    }

                    // this.submitData.org_ids = newArray.join(',');

                    let org_ids = [];
                    getCheckedNodes.forEach(item => {
                        org_ids.push(item.data.id);
                    })
                    this.submitData.org_ids = org_ids.join(',');
                    this.submitData.price = this.userForm.price;
                    submitFirst(2)
                }
                else if (this.userForm.type == 2) {
                    utilsUploadFile(this.userForm.userUploadList[0].raw, 'import').then(res => {
                        this.submitData.file_id = res.file_id;
                        submitFirst(2)
                    })
                }
                else if (this.userForm.type == 3) {
                    if (this.value.length == 0) {
                        that.$message({
                            showClose: true,
                            message: '请选择缴费学生',
                            type: 'warning'
                        });
                        return
                    }
                    if (this.userForm.price <= 0) {
                        that.$message({
                            showClose: true,
                            message: '缴费金额必须大于0',
                            type: 'warning'
                        });
                        return
                    }
                    this.submitData.user_ids = this.value.join(',');
                    this.submitData.price = this.userForm.price;
                    submitFirst(2)
                }
            }
            else if (this.active == 2) {
                let url = '/admin/v1/receive/import/confirm';
                let data = {
                    batch_no: this.batch_no
                }
                businessPost(url, data).then(res => {
                    if (res.data.status == 200) {
                        that.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.userDialog = false;
                        this.getTableData();
                    }
                    else {
                        that.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }

            function submitFirst(val) {
                let url = '/admin/v1/receive/import';
                let data = JSON.parse(JSON.stringify(that.submitData));
                businessPost(url, data).then(res => {
                    if (res.data.status == 200) {
                        that.active = val;
                        that.batch_no = res.data.data.batch_no;
                        that.startPolling();
                    }
                    else {
                        that.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        userHandleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 ` + this.userForm.userFileLimit + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        userHandleChange(file, fileList) {
            this.userForm.userUploadList = fileList;
            let reader = new FileReader();
            reader.readAsBinaryString(file.raw);
            reader.onload = evt => {
                let data = evt.target.result; // 读到的数据
                let wb = XLSX.read(data, {type: "binary"});
                let newArray = wb.Sheets.Sheet1;
                let noK = ['!margins', '!ref', 'A1', 'B1', 'C1', 'D1'];
                let searchList = [];
                for (let k in newArray) {
                    if (noK.indexOf(k) == -1) {
                        let kNameList = k.split('')
                        let num = kNameList[1] - 2;
                        if (searchList[num]) {
                            searchList[num][this.controlList[kNameList[0]]] = newArray[k].w;
                        }
                        else {
                            searchList[num] = {};
                            searchList[num][this.controlList[kNameList[0]]] = newArray[k].w;
                        }
                    }
                }
                this.userForm.tableData = searchList;
                // let outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                // console.log(outdata);
            };
        },
        getStudentTree() {
            let url = '/admin/v1/organization?type=1';
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    this.options = res.data.data;
                    let msg = {
                        id: 0,
                        name: this.userInfo.school_name
                    }
                    if (res.data.data.length > 0) {
                        msg.son = res.data.data
                    }
                    this.detailTreeData = [msg];
                }
            })
        },
        cascaderWidthDealWidth() {
            this.$nextTick(() => {
                let dom = document.querySelectorAll('.el-cascader');
                for (let i = 0; i < dom.length; i++) {
                    dom[i].style.width = '80%';
                }
            })
        },
        openImportUser(val) {
            this.selMsg = val;
            this.active = 1;
            this.submitData = {};
            this.value = [];
            this.userForm.type = '1';
            this.userForm.price = 0;
            this.userForm.user_ids = '';
            this.userForm.userFileList = [];
            this.userForm.userFileLimit = 1;
            this.userForm.userUploadList = [];
            this.userDialog = true;
            this.cascaderWidthDealWidth();
        },
        saveImport() {
            this.$refs.importForm.validate((valid) => {
                if (valid) {
                    utilsUploadFile(this.importForm.importUploadList[0].raw, 'import').then(res => {
                        let url = '/admin/v1/projects/import';
                        let data = {
                            file_id: res.file_id,
                        }
                        businessPost(url, data).then(res2 => {
                            if (res2.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res2.data.message,
                                    type: 'success'
                                });
                                utilsNotificationJump(res2.data.message, '/systemImport?module=projects')
                                this.importDialog = false;
                                this.getTableData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res2.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    })
                }
            })
        },
        importHandleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 ` + this.importForm.importFileLimit + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        importHandleChange(file, fileList) {
            this.importForm.importUploadList = fileList;
        },
        openImport() {
            this.importForm.importFileList = [];
            this.importForm.importFileLimit = 1;
            this.importForm.importUploadList = [];
            this.importDialog = true;
        },
        frRequest(id, status) {
            let url = '/admin/v1/projects/status/' + id;
            let data = {
                status: status
            }
            businessPut(url, data).then(res => {
                if (res.data.status == 200) {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'success'
                    });
                    this.getTableData();
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        openFreeze(val) {
            this.$confirm('此操作将冻结项目, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.frRequest(val.id, 0)
            }).catch(() => {

            })
        },
        openRelease(val) {
            this.$confirm('此操作将发布项目, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.frRequest(val.id, 1)
            }).catch(() => {

            })
        },
        openDel(val) {
            this.$confirm('此操作将永久删除该条记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let url = '/admin/v1/projects/' + val.id;
                businessDel(url, {}).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.getTableData();
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }).catch(() => {

            })
        },
        saveAddPlan() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let url = '/admin/v1/projects';
                    let data = {
                        name: this.ruleForm.name,
                        cate: this.ruleForm.cate,
                        price: 0,
                        explains: this.ruleForm.explains,
                        is_signup: this.ruleForm.is_signup,
                        part_payment: 0,
                        type: 2
                    }
                    if (this.ruleForm.is_signup == 0) {
                        data.part_payment = this.ruleForm.part_payment;
                        data.type = this.ruleForm.type;
                    }
                    if (this.ruleForm.is_signup == 1) {
                        data.price = this.ruleForm.price;
                    }
                    if (this.ruleForm.end_time) {
                        data.end_time = this.ruleForm.end_time + ' 23:59:59';
                    }
                    if (this.addEditTitle == '新建项目') {
                        businessPost(url, data).then(res => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.addEditVisible = false;
                                this.getTableData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                    else if (this.addEditTitle == '编辑基本信息项目') {
                        url = url + '/' + this.selMsg.id;
                        businessPut(url, data).then(res => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.addEditVisible = false;
                                this.getTableData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                }
            })
        },
        openAddEdit(sort, val) {
            this.ruleForm.name = '';
            this.ruleForm.cate = '';
            this.ruleForm.price = 0;
            this.ruleForm.part_payment = 1;
            this.ruleForm.explains = '';
            this.ruleForm.end_time = '';
            this.ruleForm.type = 1;
            this.ruleForm.is_signup = 0;
            if (sort == 'new') {
                this.addEditTitle = '新建项目'
            }
            if (sort == 'edit') {
                this.selMsg = val;
                this.ruleForm.name = this.selMsg.name;
                this.ruleForm.cate = this.selMsg.cates.id + '';
                this.ruleForm.price = this.selMsg.price;
                this.ruleForm.part_payment = this.selMsg.part_payment;
                this.ruleForm.explains = this.selMsg.explains;
                this.ruleForm.end_time = this.selMsg.end_time.split(' ')[0];
                this.ruleForm.type = this.selMsg.type;
                this.ruleForm.is_signup = this.selMsg.is_signup;
                this.addEditTitle = '编辑基本信息项目'
            }
            this.addEditVisible = true;
        },
        openQrcode() {
            this.dialogVisible = true;
        },
        cleanAll() {
            this.searchForm.project_name = '';
            this.searchForm.status = '';
            this.searchForm.cate_id = '';
            this.search();
        },
        search() {
            this.$refs.publicPageUserStudent.currentPage = 1;
            this.getTableData();
        },
        getTableData() {
            let url = '/admin/v1/projects?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    url = url + '&' + k + '=' + this.searchForm[k];
                }
            }
            this.tableLoading = true;
            businessGet(url).then(res => {
                this.tableLoading = false;
                if (res.data.status == 200) {
                    this.total = res.data.data.count;
                    let tableData = res.data.data.list;
                    tableData.forEach(item => {
                        item.statusName = Global.Global_project_status_obj[item.status];
                        item.typeName = Global.Global_project_type_obj[item.type];
                        item.is_signup_name = Global.Global_feetypes_obj[item.is_signup];
                    })
                    this.tableData = tableData;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        getSortList() {
            let url = '/admin/v1/category';
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    let cateList = [];
                    res.data.data.forEach(item => {
                        let a = {
                            value: item.id + '',
                            label: item.name
                        }
                        cateList.push(a)
                    })
                    this.cateList = cateList;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        }
    }
}
</script>

<style scoped>

@import "../../assets/css/base.css";

.container {
    width: 100%;
    height: 600px;
    box-sizing: border-box;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.payBackGround {
    width: 650px;
    font-size: 0;
    position: relative;
    margin: 0 auto;
}

.xuesheng {
    width: 320px;
    position: absolute;
    top: 260px;
    left: 50%;
    transform: translateX(-50%);
}

.schoolName {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    bottom: 80px;
}

</style>

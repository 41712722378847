<template>
    <div class="baseMain">
        <div class="systemP">
            <div class="systemPLeft">
                <div class="systemPLeftIn">
                    <div class="systemPLeftInNul">
                        <div class="systemPLeftInOne" :class="{'systemPLeftInOneAc' : leftListSel == index}"
                             v-for="(item,index) in leftList" @click="selOne(item,index)" :key="index">
                            <div>
                                <strong v-if="item.is_super_admin == 1">{{item.name}}</strong>
                                <span v-else>{{item.name}}</span>
                            </div>
                            <el-link type="primary" class="systemPLeftInButton2" @click.stop="openAdd('edit',item)"
                                     v-if="item.is_super_admin == 0">编辑
                            </el-link>
                            <el-link type="danger" class="systemPLeftInButton" @click.stop="leftDel(item)"
                                     v-if="item.is_super_admin == 0">删除
                            </el-link>
                        </div>
                    </div>
                    <div class="systemPLeftInAdd">
                        <el-button style="width: 100%;" @click="openAdd('new')">添加角色</el-button>
                    </div>
                </div>
            </div>
            <div class="systemPRight" v-if="showMsg != null">
                <div class="systemPRightIn nui-scroll">
                    <el-form label-width="200px">
                        <el-form-item label="角色名称">
                            <span>{{showMsg.role_name}}</span>
                        </el-form-item>
                        <el-form-item label="管理员">
                            <div class="peopleList">
                                <el-tag style="margin: 4px;" v-for="item in dynamicTags" :key="item.id" closable
                                        :disable-transitions="false" @close="handleClose(item)">
                                    {{item.name}}
                                </el-tag>
                                <div class="peopleListAdd">
                                    <el-popover :width="200" placement="bottom" trigger="hover">
                                        <template #reference>
                                            <el-button>扫码绑定</el-button>
                                        </template>
                                        <img style="width: 200px; height: 200px;object-fit: cover;"
                                             src="/web_img/accountBind.png" alt="">
                                    </el-popover>
                                    <el-button type="primary" @click="openAddStaff">新增管理员
                                    </el-button>
                                </div>
                            </div>
                        </el-form-item>
                        <template v-if="selOneMsg.is_super_admin == 1">
                            <el-form-item label="教职工管理权限">
                                <span>全部权限</span>
                            </el-form-item>
                            <el-form-item label="学生管理权限">
                                <span>全部权限</span>
                            </el-form-item>
                            <el-form-item label="权限列表">
                                <span>全部权限</span>
                            </el-form-item>
                        </template>
                        <template v-else>
                            <el-form-item label="角色权限配置">
                                <div style="width: 98%;">
                                    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
                                        <el-tab-pane label="教职工管理权限" name="teacher">
                                            <el-tree ref="teacherTree" :data="detailTreeData" node-key="id"
                                                     default-expand-all
                                                     :expand-on-click-node="false" :highlight-current="true"
                                                     show-checkbox
                                                     :props="defaultProps"
                                                     :default-checked-keys="teacherDefaultCheckedKeys">
                                                <template #default="{ node}">
                                                    <div class="custom-tree-node">
                                                        <span>{{ node.label }}</span>
                                                    </div>
                                                </template>
                                            </el-tree>
                                        </el-tab-pane>
                                        <el-tab-pane label="学生管理权限" name="student">
                                            <el-tree ref="studentTree" :data="detailTreeData" node-key="id"
                                                     default-expand-all
                                                     :expand-on-click-node="false" :highlight-current="true"
                                                     show-checkbox
                                                     :props="defaultProps"
                                                     :default-checked-keys="studentDefaultCheckedKeys">
                                                <template #default="{ node}">
                                                    <div class="custom-tree-node">
                                                        <span>{{ node.label }}</span>
                                                    </div>
                                                </template>
                                            </el-tree>
                                        </el-tab-pane>
                                        <el-tab-pane label="权限列表" name="permission">
                                            <el-form label-width="100px" label-position="right">
                                                <template v-for="item in treeData" :key="item.id">
                                                    <el-form-item>
                                                        <template #label>
                                                            <strong>{{item.name}}</strong>
                                                        </template>
                                                    </el-form-item>
                                                    <el-form-item :label="item2.name" v-for="item2 in item.son"
                                                                  :key="item2.id">
                                                        <el-checkbox v-model="item3.checked" :label="item3.name"
                                                                     v-for="item3 in item2.son"
                                                                     :key="item3.id"></el-checkbox>
                                                    </el-form-item>
                                                </template>
                                            </el-form>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>
                            </el-form-item>
                        </template>

                        <!--<template v-if="selOneMsg.is_super_admin == 1">-->
                        <!--<el-form-item label="教职工管理权限">-->
                        <!--<span>全部权限</span>-->
                        <!--</el-form-item>-->
                        <!--<el-form-item label="学生管理权限">-->
                        <!--<span>全部权限</span>-->
                        <!--</el-form-item>-->
                        <!--<el-form-item label="权限列表">-->
                        <!--<span>全部权限</span>-->
                        <!--</el-form-item>-->
                        <!--</template>-->
                        <!--<template v-else>-->
                        <!--<el-form-item label="教职工管理权限">-->
                        <!--<el-button type="primary" @click="openPermission('2')">编辑</el-button>-->
                        <!--</el-form-item>-->
                        <!--<el-form-item label="学生管理权限">-->
                        <!--<el-button type="primary" @click="openPermission('1')">编辑</el-button>-->
                        <!--</el-form-item>-->
                        <!--<el-form-item label="权限列表">-->
                        <!--<el-form label-width="100px" label-position="right">-->
                        <!--<template v-for="item in treeData" :key="item.id">-->
                        <!--<el-form-item>-->
                        <!--<template #label>-->
                        <!--<strong>{{item.name}}</strong>-->
                        <!--</template>-->
                        <!--</el-form-item>-->
                        <!--<el-form-item :label="item2.name" v-for="item2 in item.son" :key="item2.id">-->
                        <!--<el-checkbox v-model="item3.checked" :label="item3.name"-->
                        <!--v-for="item3 in item2.son"-->
                        <!--:key="item3.id"></el-checkbox>-->
                        <!--</el-form-item>-->
                        <!--</template>-->
                        <!--</el-form>-->
                        <!--</el-form-item>-->
                        <!--<el-form-item label=" ">-->
                        <!--<el-button type="primary" @click="saveTree" v-if="treeData.length > 0">保存</el-button>-->
                        <!--</el-form-item>-->
                        <!--</template>-->
                    </el-form>
                </div>
                <div class="systemPRightBottom" v-if="selOneMsg.is_super_admin != 1">
                    <template v-if="activeName == 'teacher'">
                        <el-button type="primary" @click="savePermission">教职工权限保存</el-button>
                    </template>
                    <template v-else-if="activeName == 'student'">
                        <el-button type="primary" @click="savePermission">学生权限保存</el-button>
                    </template>
                    <template v-else-if="activeName == 'permission'">
                        <el-button type="primary" @click="saveTree" v-if="treeData.length > 0">功能权限保存</el-button>
                    </template>
                </div>
            </div>
        </div>
        <el-dialog :title="dialogTitle" v-model="dialogVisible" width="600px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-drawer title="添加管理员" v-model="addPeopleVisible" :close-on-click-modal="false" :destroy-on-close="true"
                   size="80%">
            <div class="systemDrawer">
                <div class="systemDrawerLeft">
                    <div class="systemDrawerLeftIn">
                        <div class="baseClassLeftInTop">
                            <el-input style="width: 300px;" placeholder="请输入要搜索的内容"
                                      suffix-icon="el-icon-search" v-model="peopleFilterText"></el-input>
                        </div>
                        <div class="baseClassLeftInMain nui-scroll">
                            <el-tree ref="addTree" :data="peopleTreeData" node-key="id" default-expand-all
                                     :expand-on-click-node="false" :filter-node-method="peopleFilterNode"
                                     @node-click="lookDetail" :highlight-current="true" :props="defaultProps">
                                <template #default="{data}">
                                    <div class="custom-tree-node">
                                        <span>{{ data.name }}</span>
                                    </div>
                                </template>
                            </el-tree>
                        </div>
                    </div>
                </div>
                <div class="systemDrawerCenter" v-if="treeLookMsg != null && addPeopleVisible">
                    <div class="baseSearch clearfloat">
                        <div class="baseSearchOne">
                            <div class="baseSearchOneLeft">
                                <span>学工号/姓名：</span>
                            </div>
                            <div class="baseSearchOneRight">
                                <el-input v-model="detailSearch.key_words" placeholder="请输入内容"></el-input>
                            </div>
                        </div>
                        <div class="baseSearchOne">
                            <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                            <el-button @click="cleanAll">清空</el-button>
                        </div>
                    </div>
                    <div class="baseTable" style="margin-top: 10px;">
                        <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                            <el-table-column prop="card_number" label="学工号"></el-table-column>
                            <el-table-column prop="name" label="姓名"></el-table-column>
                            <el-table-column prop="duties" label="职务"></el-table-column>
                            <el-table-column prop="orgs.name" label="部门"></el-table-column>
                            <el-table-column prop="phone" label="手机号"></el-table-column>
                            <!--<el-table-column prop="teacher_grade_name" label="班级权限"></el-table-column>-->
                            <el-table-column label="操作">
                                <template #default="scope">
                                    <el-link class="tableButton" type="primary" @click.prevent="moveStaff(scope.row)">
                                        添加
                                    </el-link>
                                </template>
                            </el-table-column>
                        </publicTable>
                    </div>
                    <div class="basePage" style="margin-top: 4px;">
                        <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                                    @pageChange="getStaffData"></publicPage>
                    </div>
                </div>
                <div class="systemDrawerRight" v-if="treeLookMsg != null">
                    <div class="systemDrawerRightIn">
                        <el-alert class="systemDrawerRightInText" title="已选择" type="success" effect="dark"
                                  :closable="false"></el-alert>
                        <div class="systemDrawerRightMain">
                            <el-tag class="systemDrawerTags" v-for="tag in tags" :key="tag.id" closable
                                    @close="peopleHandleClose(tag)">
                                {{tag.name}}
                            </el-tag>
                        </div>
                        <div class="systemPLeftInAdd">
                            <el-button type="primary" style="width: 100%;" @click="saveAddStaff">确认</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
        <!--<el-dialog :title="permissionTitle" v-model="permissionVisible" width="600px">-->
        <!--<el-form label-width="180px">-->
        <!--<el-form-item label="请勾选需要的权限" prop="name">-->
        <!--<el-tree ref="jzgxsTree" :data="detailTreeData" node-key="id" default-expand-all-->
        <!--:expand-on-click-node="false" :highlight-current="true" show-checkbox :props="defaultProps"-->
        <!--:default-checked-keys="defaultCheckedKeys">-->
        <!--<template #default="{ node}">-->
        <!--<div class="custom-tree-node">-->
        <!--<span>{{ node.label }}</span>-->
        <!--</div>-->
        <!--</template>-->
        <!--</el-tree>-->
        <!--</el-form-item>-->
        <!--</el-form>-->
        <!--<template #footer>-->
        <!--<div class="dialog-footer">-->
        <!--<el-button type="primary" @click="savePermission">确 定</el-button>-->
        <!--</div>-->
        <!--</template>-->
        <!--</el-dialog>-->
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import {businessGet, businessPost, businessDel, businessPut} from '../../api/business.js'

    import Global from "../../Global"

    export default {
        name: "systemPermission",
        components: {
            publicPage,
            publicTable
        },
        data() {
            return {
                userInfo: null,
                leftList: [],
                leftListSel: 0,
                selOneMsg: null,
                showMsg: null,
                dialogTitle: '',
                dialogVisible: false,
                ruleForm: {
                    id: null,
                    name: '',
                },
                rules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                treeData: [],
                dynamicTags: [],
                addPeopleVisible: false,
                treeLookMsg: null,
                peopleFilterText: '',
                peopleTreeData: [],
                detailSearch: {
                    key_words: '',
                },
                searchHeight: 0,
                tableData: [],
                total: 0,
                tags: [],
                detailTreeData: null,
                defaultProps: {
                    children: 'son',
                    label: 'name',
                },
                teacherDefaultCheckedKeys: [],
                studentDefaultCheckedKeys: [],
                activeName: '',
            }
        },
        watch: {
            peopleFilterText(val) {
                this.$refs.addTree.filter(val);
            }
        },
        created() {
            this.userInfo = JSON.parse(localStorage.getItem(Global.GlobalUserInfoLocalName));
            this.getRoleList();
        },
        methods: {
            handleClick() {
                this.studentDefaultCheckedKeys = [];
                this.teacherDefaultCheckedKeys = [];
                if (this.activeName == 'teacher' || this.activeName == 'student') {
                    this.openPermission(this.activeName)
                }
            },
            saveAddStaff() {
                if (this.tags.length == 0) {
                    this.$message({
                        showClose: true,
                        message: '请从列表中添加人员',
                        type: 'warning'
                    });
                }
                let url = '/admin/v1/role/op_admin';
                let newArray = [];
                this.tags.forEach(item => {
                    newArray.push(item.id)
                })
                let data = {
                    role_id: this.selOneMsg.id,
                    type: 1,
                    user_id: newArray.join(',')
                }
                businessPost(url, data).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.addPeopleVisible = false;
                        this.selOne(this.selOneMsg, this.leftListSel);
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            peopleHandleClose(item) {
                this.tags.splice(this.tags.indexOf(item), 1)
            },
            moveStaff(val) {
                let item = {
                    id: val.id,
                    name: val.name,
                }
                let newArray = this.tags.filter(item => {
                    if (item.id == val.id) {
                        return true
                    }
                })
                if (newArray.length == 0) {
                    this.tags.push(item)
                }
                else {
                    this.$message({
                        showClose: true,
                        message: '已添加过',
                        type: 'warning'
                    });
                }
            },
            cleanAll() {
                this.detailSearch.key_words = '';
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getStaffData();
            },
            getStaffData() {
                let url = '/admin/v1/teacher?type=2&org_id=' + this.treeLookMsg.id + '&page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                if (this.detailSearch.key_words) {
                    url = url + '&key_words=' + this.detailSearch.key_words
                }
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        this.tableData = res.data.data.user_list;
                    }
                })
            },
            lookDetail(val) {
                this.treeLookMsg = JSON.parse(JSON.stringify(val));
                this.$nextTick(() => {
                    let baseSearchDom = document.querySelector('.baseSearch');
                    let searchHeight = baseSearchDom.offsetHeight + 30;
                    this.searchHeight = searchHeight;
                    this.cleanAll();
                })
            },
            peopleFilterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            getPeopleTreeData() {
                let url = '/admin/v1/organization?type=2';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let msg = {
                            id: 0,
                            name: this.userInfo.school_name
                        }
                        if (res.data.data.length > 0) {
                            msg.son = res.data.data;
                        }
                        this.peopleTreeData = [msg];
                        this.lookDetail(msg);
                    }
                })
            },
            openAddStaff() {
                this.tags = [];
                this.getPeopleTreeData();
                this.addPeopleVisible = true;
            },
            handleClose(item) {
                this.$confirm('删除后将无法恢复，需要重新添加，是否确认删除?', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/role/op_admin';
                    let data = {
                        role_id: this.selOneMsg.id,
                        type: 2,
                        user_id: item.id
                    }
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.selOne(this.selOneMsg, this.leftListSel);
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            savePermission() {
                // var a = this.$refs.assignTree.getCheckedKeys().concat(this.$refs.assignTree.getHalfCheckedKeys());
                let url = '/admin/v1/role/ts_auth/' + this.selOneMsg.id;
                let data = {}
                if (this.activeName == 'teacher') {
                    data.type = 2;
                    let newArray = this.$refs.teacherTree.getCheckedKeys();
                    data.org_ids = newArray.join(',');
                }
                else if (this.activeName == 'student') {
                    data.type = 1;
                    let newArray = this.$refs.studentTree.getCheckedKeys();
                    data.org_ids = newArray.join(',');
                }
                businessPut(url, data).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.selOne(this.selOneMsg, this.leftListSel);
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            openPermission(type) {
                let msg = '';
                if (type == 'student') {
                    this.studentDefaultCheckedKeys = this.showMsg.student_org;
                    msg = 1
                }
                else if (type == 'teacher') {
                    this.teacherDefaultCheckedKeys = this.showMsg.teacher_org;
                    msg = 2
                }
                let url = '/admin/v1/organization?type=' + msg;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let msg = {
                            id: 0,
                            name: this.userInfo.school_name
                        }
                        if (res.data.data.length > 0) {
                            msg.son = res.data.data
                        }
                        this.detailTreeData = [msg];
                    }
                })

            },
            saveTree() {
                let newArray = getTreeSel(this.treeData, [])
                let url = '/admin/v1/role/auth_edit';
                let data = {
                    role_id: this.selOneMsg.id,
                    auth_id: newArray.join(',')
                }
                businessPost(url, data).then(res => {
                    if (res.data.status == 200) {
                        this.selOne(this.selOneMsg, this.leftListSel);
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })

                function getTreeSel(treeData, ids) {
                    let newArray = ids;
                    for (let i = 0; i < treeData.length; i++) {
                        let item = treeData[i];
                        if ('checked' in item && item.checked) {
                            newArray.push(item.id)
                        }
                        if ('son' in item) {
                            getTreeSel(item.son, newArray)
                        }
                    }
                    return newArray
                }
            },
            getTreeData(selArray) {
                let url = '/admin/v1/auth';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let selList = selArray
                        let newArray = treeSet(res.data.data, [], selList);
                        this.treeData = newArray;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })

                function treeSet(oldTree, newTree, selList) {
                    let treeData = newTree;
                    for (let i = 0; i < oldTree.length; i++) {
                        let item = oldTree[i];
                        let a = {
                            id: item.id,
                            name: item.name
                        }
                        if (item.type == 2) {
                            if (selList.indexOf(item.id) == -1) {
                                a.checked = false
                            }
                            else {
                                a.checked = true
                            }
                        }
                        if ('son' in item) {
                            a.son = treeSet(item.son, [], selList);
                        }
                        treeData.push(a)
                    }
                    return treeData
                }
            },
            selOne(val, index) {
                this.selOneMsg = val;
                this.leftListSel = index;
                this.treeData = [];
                let url = '/admin/v1/role/auth_info';
                if (this.selOneMsg.is_super_admin == 1) {
                    url += '?is_super_admin=1';
                }
                else if (this.selOneMsg.is_super_admin == 0) {
                    url += '?role_id=' + this.selOneMsg.id;
                }
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.showMsg = res.data.data;
                        let admin_list = this.showMsg.admin_list;
                        let newArray = [];
                        for (let k in admin_list) {
                            let a = {
                                id: k,
                                name: admin_list[k]
                            }
                            newArray.push(a)
                        }
                        this.dynamicTags = newArray;
                        if ('allow_auth' in this.showMsg) {
                            this.getTreeData(this.showMsg.allow_auth);
                        }
                        if (this.activeName == '') {
                            this.activeName = 'teacher';
                        }
                        this.handleClick();
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            leftDel(item) {
                this.$confirm('此操作将删除该角色(' + item.name + '), 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/role' + '/' + item.id;
                    businessDel(url, {}).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getRoleList();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            saveAdd() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let url = '/admin/v1/role';
                        let data = {
                            name: this.ruleForm.name
                        }
                        if (this.dialogTitle == '添加角色') {
                            businessPost(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.dialogVisible = false;
                                    this.getRoleList();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                        else if (this.dialogTitle == '编辑角色') {
                            url += '/' + this.ruleForm.id;
                            businessPut(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.dialogVisible = false;
                                    this.getRoleList();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                    }
                })
            },
            openAdd(sort, val) {
                if (sort == 'new') {
                    this.dialogTitle = '添加角色';
                    this.ruleForm.name = '';
                }
                else if (sort == 'edit') {
                    this.dialogTitle = '编辑角色';
                    this.ruleForm.name = val.name;
                    this.ruleForm.id = val.id;
                }
                this.dialogVisible = true;
            },
            getRoleList() {
                let url = '/admin/v1/role';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let newArray = [];
                        if (this.userInfo.is_super_admin == 1) {
                            let a = {
                                id: 0,
                                is_super_admin: 1,
                                name: '超级管理员',
                                school_code: '123456789'
                            }
                            newArray.push(a)
                        }
                        res.data.data.forEach(item => {
                            item.is_super_admin = 0
                            newArray.push(item)
                        })
                        this.leftList = newArray;
                        this.selOne(this.leftList[0], 0)
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .systemP {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        position: relative;
        padding: 10px 20px 10px 290px;
    }

    .systemPLeft {
        width: 290px;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .systemPLeftIn {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 8px 0px 42px 0px;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #DADEDD;
        position: relative;
    }

    .systemPLeftInNul {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
    }

    .systemPLeftInOne {
        width: 100%;
        height: 26px;
        box-sizing: border-box;
        padding-left: 10px;
        padding-right: 100px;
        position: relative;
        text-align: left;
        font-size: 14px;
        line-height: 26px;
        cursor: pointer;
    }

    .systemPLeftInOne:hover {
        background-color: #f5f7fa;
    }

    .systemPLeftInOneAc {
        background-color: #ecf5ff;
    }

    .systemPLeftInButton {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .systemPLeftInButton2 {
        position: absolute;
        right: 44px;
        top: 50%;
        transform: translateY(-50%);
    }

    .systemPLeftInAdd {
        width: 90%;
        font-size: 0;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
    }

    .systemPRight {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: 42px;
        position: relative;
    }

    .systemPRightIn {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
    }

    .systemPRightBottom {
        width: 100%;
        height: 32px;
        box-sizing: border-box;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .systemDrawer {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        position: relative;
        padding: 0px 330px 0px 330px;
    }

    .systemDrawerLeft {
        width: 320px;
        height: 100%;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
    }

    .systemDrawerLeftIn {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #DADEDD;
        position: relative;
        padding-top: 72px;
    }

    .systemDrawerRight {
        width: 320px;
        height: 100%;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
    }

    .systemDrawerRightIn {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #DADEDD;
        position: relative;
        padding-top: 42px;
        padding-bottom: 42px;
    }

    .systemDrawerRightInText {
        font-size: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .systemDrawerRightMain {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
    }

    .systemDrawerTags {
        margin: 4px;
    }

    .systemDrawerCenter {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .peopleList {
        width: 100%;
        min-height: 32px;
        box-sizing: border-box;
        padding-right: 240px;
        position: relative;
    }

    .peopleListAdd {
        width: 220px;
        height: 32px;
        position: absolute;
        top: 0;
        right: 0;
    }

</style>
